import { useCartDefaultActions } from '@/composables/useCartDefaultActions'
import expressCheckoutCartApi from "@/backend/services/expressCheckoutCart";

export default {
  ...useCartDefaultActions(expressCheckoutCartApi),
  async deleteItem({ dispatch, commit, getters }, item) {
    // Delete the item
    const response = await expressCheckoutCartApi.deleteItem(item.id);
    commit("setCart", response);
  },

  async submitOrder({ commit, getters, rootGetters }, orderData) {
    // Get the account
    const billingAddress = rootGetters["account/billingAddress"];
    const shippingAddress = rootGetters["account/shippingAddress"];

    const params = {
      email: orderData.email,
      additionalPayment: getters['additionalPayment'],
      shipping_address: shippingAddress,
      billing_address: billingAddress,
      payment_points: {
        amount: getters['pointsDiscount'],
      },
      payment_financial: null
    };
    const post = Object.assign(params, { cart: getters["cart"] });

    return await expressCheckoutCartApi.submitOrder(post);
  }
}