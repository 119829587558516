import { cookieService } from "@/service/cookieConsentService";
import store from "@/store/store";

export function useCartDefaultActions(service) {
  return {
    async get({ commit }) {
      store.dispatch("apiLoading/setLoading", true);
      const data = await service.get();
      commit("setCart", data);
      store.dispatch("apiLoading/setLoading", false);
      return data;
    },

    async addItem({ dispatch }, product) {
      store.dispatch("apiLoading/setLoading", true);
      const response = await service.addItem(product);
      // const response = {"error":"Not possible to add article!"}
      dispatch("handleResponse", response);
    },

    async updateItem({ dispatch }, product) {
      store.dispatch("apiLoading/setLoading", true);
      const response = await service.updateItem(product);
      dispatch("handleResponse", response);
    },

    async redeemPoints({ commit }, params) {
      const data = await service.payment(params);
      commit("setCart", data);
      if (cookieService.isStatisticsGroupEnabled()) {
        const stepper = store.getters["cart/getStepper"];
        if (stepper || params.module == 'expressCheckoutCart') {
          commit("trackCart", data);
        }
      }
    },

    async validateVoucher({ commit }, params) {
      store.dispatch("apiLoading/setLoading", true);
      const data = await service.validateVoucher(params);

      //@todo setLogger? Why here? Ask Haroon
      commit("setLogger", data);
      store.dispatch("apiLoading/setLoading", false);
      return data;
    },

    async validateExpressVoucher({ commit }, params) {
      store.dispatch("apiLoading/setLoading", true);
      const data = await service.validateExpressVoucher(params);
      commit("setLogger", data);
      store.dispatch("apiLoading/setLoading", false);
      return data;
    },

    async getLastOrder() {
      return service.getLastOrder();
    },

    handleResponse({ commit, dispatch }, response) {
      if (response.error && response.error === 3000) {
        // Notify the user the max allowed number is being exceeded
        let notification = {
          code: "ITEM_LIMITED_AMOUNT",
          type: "info",
        };
        dispatch("notification/set", notification, { root: true });
      }
      else if (response.error && response.error === 'Not possible to add article!') {
        // Notify the user it's not possible to generate mixed shopping cart
        let notification = {
          code: "NO_MIXED",
          type: "alert_no_mix",
        };
        dispatch("notification/set", notification, { root: true });
        // dispatch("cart/get")
      }
      else {
        commit("setCart", response);
        if (cookieService.isStatisticsGroupEnabled()) {
          commit("trackCart", response);
        }
      }
      store.dispatch("apiLoading/setLoading", false);
    },
  };
}
