<template>
  <button v-on:click="clicked" :class="cssClass" :type="type">
    {{ text }}
  </button>
</template>
<script>
  export default {
    name: "Button",
    props: {
      text: String,
      cssClass: {
        type: String,
        default: "green",
        validator: (prop) => {
          return ["green", "blue", "white", "red", "disabled"].includes(prop);
        },
        required: true,
      },
      type: {
        type: String,
        default: "button",
      },
    },
    computed: {
      /*cssClass() {
      let cssClass = "button";

      cssClass += this.primary ? " button--primary" : " button--secondary";

      return cssClass;
    },*/
    },
    methods: {
      clicked() {
        this.$emit("clicked");
      },
    },
  };
</script>
<style lang="postcss" scoped>
  button {
    align-self: flex-start;
    @apply outline-none box-border max-w-full w-full rounded-md py-2 mt-1;
    height: 48px;
    font-size: 16px;
    padding: 6px 16px;

    .button-inner {
      @apply px-6 py-3;
    }

    &.green {
      @apply border-green-light bg-green-light border;
      @apply text-white;
      &:focus {
        @apply bg-green-dark text-white border-green-dark outline-none;
      }
      &:active:not(:disabled) {
        @apply bg-green-dark text-white border-green-dark;
      }
      &:disabled:not(.button--loading) {
        @apply font-semibold bg-gray-disable text-gray-disableColor border-none cursor-not-allowed;
      }
      &:hover {
        @apply bg-green-dark text-white border-green-dark;
      }
    }

    &.red {
      @apply border-red-light bg-red-light border;
      @apply text-white;
      &:focus {
        @apply bg-red-light text-white border-red-light outline-none;
      }
      &:active:not(:disabled) {
        @apply bg-red-light text-white border-red-light;
      }
      &:disabled:not(.button--loading) {
        @apply text-tertiary-text border-red-light;
      }
      &:hover {
        @apply bg-red-light text-white border-red-light;
      }
    }

    &.blue {
      @apply border-blue bg-blue border;
      @apply text-white;
      &:focus {
        @apply bg-blue-focus text-white border-blue outline-none;
      }
      &:active:not(:disabled) {
        @apply bg-blue text-white border-blue;
      }
      &:disabled:not(.button--loading) {
        @apply text-tertiary-text border-blue;
      }
      &:hover {
        @apply bg-blue-focus text-white border-blue;
      }
    }

    &.white {
      @apply border-white bg-white border shadow-md;
      @apply text-blue;
      &:focus {
        @apply bg-white text-blue border-white outline-none;
      }
      &:active:not(:disabled) {
        @apply bg-white text-blue border-white;
      }
      &:disabled:not(.button--loading) {
        @apply text-tertiary-text border-white;
      }
      &:hover {
        @apply bg-white text-blue border-white;
      }
    }
  }
</style>
