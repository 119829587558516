export default {
    get(key, defaultType = String) {
        if (this.has(key)) {
            const value = window.localStorage.getItem(key);
            return this.__process(defaultType, value);
        }
        return null
    },
    set(key, rawValue, type) {
        const value = type && [Array, Object].includes(type)
            ? JSON.stringify(rawValue)
            : rawValue;

        window.localStorage.setItem(key, value);
    },
    __process(type, value) {
        switch (type) {
            case Boolean:
                return value === 'true';
            case Number:
                return parseFloat(value);
            case Array:
                try {
                    const array = JSON.parse(value);

                    return Array.isArray(array) ? array : []
                } catch (e) {
                    return [];
                }
            case Object:
                try {
                    return JSON.parse(value);
                } catch (e) {
                    return {};
                }
            default:
                return value;
        }
    },
    has (key) {
        return !!window.localStorage.getItem(key)
    },
    remove(key) {
      if (this.has(key)) {
        window.localStorage.removeItem(key);
      }
    },
    clear() {
        window.localStorage.clear();
    },
    // clearCookies() {
    //     document.cookie.replace(/(?<=^|;).+?(?=\=|;|$)/g, name => location.hostname.split('.').reverse().reduce(domain => (domain=domain.replace(/^\.?[^.]+/, ''),document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`,domain), location.hostname));
    // }
}
