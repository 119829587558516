<template>
      <div class="spinner-box">
            <div class="spinner"></div>
      </div>
</template>

<script>
      export default {
            name: "Spinner"
      };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
      .spinner-box {
            position: fixed;
            z-index: 999;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.6) no-repeat;
            background-size: cover;

            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99999;
            .spinner {
                  box-sizing: border-box;
                  display: inline-block;
                  position: relative;
                  width: 112px;
                  height: 112px;
                  border-radius: 50%;

                  border-top: 16px solid #0046aa;
                  border-right: 16px solid #f2f5fa;
                  border-bottom: 16px solid #f2f5fa;
                  border-left: 16px solid #f2f5fa;
                  animation: wiggle 1.5s linear infinite;
            }
      }

      @keyframes wiggle {
            0% {
                  transform: rotate(0);
            }

            100% {
                  transform: rotate(360deg);
            }
      }
</style>
