import parseTrackingValue from "@/service/parseTrackingValue";
import { each, extend, isString } from "lodash-es";
import store from "@/store/store";
import tService from "@/service/tService";
import tProductInformationService from "@/service/tProductInformationService";
import amountFormatter from '../filters/amountFormatter'
import numbersConvert from "@/helpers/numbersConvert";

import EventBus from "@/helpers/event-bus";
import router from "@/router";

export default class tCartInformationService {
  constructor() {
    this.cart = {
      item: [],
      price: {
        cartTotalPoints: 0,
      },
    };
    this.priceInformation = {};
    this.productsLoaded = false;
    this.additionalPaymentMethod = "";
    let service = this;
    EventBus.on("paymentSelected", (arg) => {
      service.additionalPaymentMethod = arg;
      service.addPaymentInformation();
    });
    this.currentRoute = router?.currentRoute;
  }

  setCartInformation() {
    if (this.isExpressCheckout()) {
      this.cart.price.additionalPaymentSum = amountFormatter(String(store.getters["expressCheckoutCart/additionalPayment"]), 'EUR');
      this.cart.price.voucherDiscount = String(store.getters["expressCheckoutCart/voucherDiscount"]);
      this.cart.price.usedPoints = String(store.getters["expressCheckout/pointsDiscount"]);
    }
    else {
      this.cart.price.additionalPaymentSum = amountFormatter(String(store.getters["cart/additionalPayment"]), 'EUR');
      this.cart.price.voucherDiscount = String(store.getters["cart/voucherDiscount"]);
      this.cart.price.usedPoints = String(store.getters["cart/pointsDiscount"]);
    }
  }

  isExpressCheckout = () => {
    return this.currentRoute.value.name === "express-bestellen";
  };

  setItem = (item) => {
    //console.info('################item##################', item);
    let cartItem = {
      productInfo: {
        productID: String(item.product_sku),
        productName: item.name,
      },
      quantity: String(item.qty),
      category: {
        primaryCategory: parseTrackingValue(String(isString(item?.cat_name) ? item?.cat_name : ""), true),
      },

      price: {
        totalPoints: String(item.total),
      },
    };

    this.cart.item.push(cartItem);
    if (this.isExpressCheckout()) {
      this.cart.price.cartTotalPoints = numbersConvert.thousandSeparator(store.getters["expressCheckoutCart/grandTotalWithVoucherDiscount"])
    } else {
      this.cart.price.cartTotalPoints = numbersConvert.thousandSeparator(store.getters["cart/grandTotalWithVoucherDiscount"])

    }
  };

  addPriceInformation = (data) => {
    extend(this.priceInformation, data);
  };

  init = () => {
    let cartItems = store.getters["cart/getItems"];
    each(cartItems, this.setItem);
  };
  addShippingInformation = () => {
    let stepper = store.getters["cart/getStepper"];
    if (stepper == 3) {
      this.addPriceInformation({
        shippingFee: "0",
        shippingMethod: "post_at",
      });
    }
  };
  addPaymentInformation = async () => {
    let mapping = {
      Rechnung: "invoice",
    };

    if (mapping[this.additionalPaymentMethod]) {
      this.additionalPaymentMethod = mapping[this.additionalPaymentMethod];
    }

    this.addPriceInformation({
      additionalPaymentMethod: this.additionalPaymentMethod,
      additionalPaymentFee: "0", // not available in this scope
    });
  };
  get = () => {
    if (this.cart) {
      Object.assign(this.cart.price, this.priceInformation);
    }

    return this.cart;
  };
  trackCart = (cartInfo) => {
    let pageName = ""
    const trackingService = new tService();
    const tPInformationService = new tProductInformationService();
    const stepper = store.getters["cart/getStepper"];
    this.init();
    trackingService.init(this.get());

    if (stepper == 2) {
      pageName = "address_payment"
    }
    else if (stepper == 3) {
      pageName = "review_order"
    }
    else {
      pageName = this.currentRoute.value.meta.trackingName
    }

    if (window.location.href.indexOf("bestellen") !== -1) {
      this.setCartInformation();
      this.addShippingInformation();
      this.addPaymentInformation();
    }
    else {
      if (Array.isArray(cartInfo)) {
        for (let index in cartInfo) {
          tPInformationService.setProduct(null, cartInfo[index]);
        }
      }
    }

    let persistentPage = {
      pageName: pageName,
    };

    trackingService.setCategory({
      primaryCategory: "rewards",
      subCategory: window.location.href.indexOf("bestellen") === -1 ? "shoppingcart" : "checkout",
    });
    trackingService.setPageInfo(persistentPage);
    if (tPInformationService.products.length > 0) {
      trackingService.setProducts(tPInformationService.products);
    }
    setTimeout(() => {
      trackingService.updateCart(this.get());
      trackingService.track()
    }, 500);
  };
}
