import api from "@/backend/api";

export default {
  getCategoryTree() {
    return api.get("/catalog/categories");
  },
  getCategoryByUrlKey(urlKey) {
    return api.get(`/catalog/category/${urlKey}`);
  },
  getProducts(params) {
    return api.get("/catalog/product/limited", params);
  },
  getProductBySku(sku) {
    return api.get(`/catalog/product/detail/${sku}`);
  },
  getDifferentDenominationProducts(sku) {
    return api.get(`/catalog/product/detail/${sku}`);
  },
  getBrands() {
    return api.get("/catalog/brandsproduct");
  },
  getContacts() {
    return api.get("/manufacturers/contacts");
  },
  getFaqs() {
    return api.get("/catalog/landingpages", { tag: "FAQ" });
  },
  getNews() {
    return api.get("/catalog/landingpages", { tag: "NEWS" });
  },
  getLandingPage(urlKey) {
    return api.get("/catalog/landingpage/" + urlKey);
  },
  getTandC() {
    return api.get("/catalog/landingpages", { tag: "T&C" });
  },
  getPrivacy() {
    return api.get("/catalog/landingpages", { tag: "PRIVACY" });
  },
  getCookiePolicy() {
    return api.get("/catalog/landingpages", { tag: "COOKIE" });
  },
};
