<template>
      <section class="fake-nav-menu" v-if="showSortingInfo && searchText">
            <div>
                  <div class="back-button">
                        <div @click="clearSearchFilterAction()">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#0046AA" /></svg
                              >{{
                                    `
                  ${t("LBL_BACK")}`
                              }}
                        </div>
                  </div>
            </div>
      </section>
      <div class="md:container row-gap-1 mx-auto mt-12 mobile:w-full" v-if="showSortingInfo">
            <div class="text-blue font-normal text-sm py-4 px-2 m-2 border border-t border-b border-l-0 border-r-0">
                  <a @click="showInfo" class="showInfoMobile"
                        ><span>{{ t("LBL_INFORMATION") }}</span
                        ><span :class="{ down: !showInfoPanel }" class="arrow up"></span
                  ></a>
            </div>
            <div v-if="showInfoPanel" class="show-info">
                  <div v-html="t('MSG_INFORMATION')"></div>
            </div>
      </div>
      <div class="z-10" :class="{ docked: docked || showMenu }" ref="mainMenu">
            <section class="main-menu-container" id="main-menu" v-bind:class="{ 'no-shadow': showMenu }">
                  <div class="main-menu-container__grid grid xxs:grid-cols-4 mobile:grid-cols-7 lg:grid-cols-7">
                        <div class="col-span-1">
                              <div class="menu-item-wrapper menu-item-wrapper__category" @click="toggleCatMenu('toggle')">
                                    <div class="dropdown inline-block relative text-center w-full category-menu">
                                          <img class="text-black rounded inline-flex items-center category-menu" src="../../assets/img/icons/ic_shoppinglist.svg" alt="Kategorien" />
                                          <div class="text-xs mt-3 text-center w-full category-menu menu-item__text">Kategorien</div>
                                    </div>
                              </div>
                        </div>
                        <div class="menu-item-wrapper menu-item-wrapper__points col-span-2">
                              <div class="grid grid-flow-col gap-0 header-text w-full pr-3 -mt-6 line-wrap">
                                    <div class="self-start">Punkte :</div>
                                    <div class="col-span-2 self-end text-right text-black">
                                          <span class="text-blue">{{ updatdSliderMinValues }}</span> °P bis <span class="text-blue">{{ updatdSliderMaxValues }}</span> °P
                                    </div>
                              </div>
                              <div class="w-full pl-3 pr-4 flex-col justify-center items-center -mt-1">
                                    <input-slider
                                          aria-label="slider"
                                          ref="pointsSlider"
                                          :min="sliderMin"
                                          :max="sliderMax"
                                          :defaultRange="defaultRange"
                                          @slider-out-put="sliderOutPut($event)"
                                          @slider-out-put-fetch="sliderOutPutFetch($event)"
                                    />
                              </div>
                        </div>
                        <div class="menu-item-wrapper menu-item-wrapper__points-mobile col-span-1 cursor-pointer" @click="showPointsSlider()" :class="{ 'bg-white': showPoints }">
                              <div class="dropdown inline-block relative text-center w-full">
                                    <img class="text-black rounded inline-flex items-center" src="../../assets/img/icons/ic_points_op.svg" alt="Kategorien" />
                                    <div class="text-xs mt-3 relative text-center w-full menu-item__text">Punkte filtern</div>
                              </div>
                        </div>
                        <div class="points-mobile__slider" v-if="showPoints">
                              <div class="grid grid-cols-3 gap-0 header-text w-full">
                                    <div class="self-start text-black">Punkte :</div>
                                    <div class="col-span-2 self-end text-right text-black">
                                          <span class="text-blue">{{ updatdSliderMinValues }}</span> °P bis <span class="text-blue">{{ updatdSliderMaxValues }}</span> °P
                                    </div>
                              </div>
                              <div class="w-full grid gap-0">
                                    <input-slider
                                          :min="sliderMin"
                                          :max="sliderMax"
                                          :defaultRange="defaultRange"
                                          @slider-out-put="sliderOutPut($event)"
                                          @slider-out-put-fetch="sliderOutPutFetch($event)"
                                    ></input-slider>
                              </div>
                        </div>
                        <div class="col-span-2 hidden mobile:block lg:block">
                              <div class="menu-item-wrapper text-xs">
                                    <div class="header-text mt-2">Suche:</div>
                                    <div class="w-full pr-2 flex-col justify-center items-center">
                                          <text-input @onEnter="searchProduct" :value="searchText" :placeholder="'Prämien, Marken, ...'" cssClass="searchinput"></text-input>
                                    </div>
                              </div>
                        </div>
                        <div class="col-span-1">
                              <div class="menu-item-wrapper menu-item-wrapper__sort" @click="showSortMenuItems()" :class="{ 'bg-white': showSortMenu }">
                                    <div class="dropdown inline-block relative text-center w-full">
                                          <img class="sort-image" src="../../assets/img/icons/ic_filter_list.svg" alt="sortieren" />
                                    </div>
                                    <div class="sort-selected text-blue-light text-center w-full menu-item__text pointer-events-none">
                                          {{ sortSelected }}
                                    </div>
                                    <div class="sort-menu space-y-1" v-if="showSortMenu">
                                          <div
                                                class="hover:bg-lightblue hover:text-white active:bg-lightblue active:text-white focus:bg-lightblue focus:text-white p-1 pb-1"
                                                @click="clearSortSelected()"
                                          >
                                                Bestseller
                                          </div>
                                          <div
                                                class="hover:bg-lightblue hover:text-white active:bg-lightblue active:text-white focus:bg-lightblue focus:text-white p-1 pb-1"
                                                @click="sortBy('price', 'ASC', 'Preis aufsteigend')"
                                          >
                                                Preis aufsteigend
                                          </div>
                                          <div
                                                class="hover:bg-lightblue hover:text-white active:bg-lightblue active:text-white focus:bg-lightblue focus:text-white p-1 pb-1"
                                                @click="sortBy('price', 'DESC', 'Preis absteigend')"
                                          >
                                                Preis absteigend
                                          </div>
                                    </div>
                              </div>
                        </div>

                        <div class="col-span-1">
                              <div class="menu-item-wrapper menu-item-wrapper-warenkorb menu-item-wrapper__cart" v-if="!showMenu && !showPoints">
                                    <shopping-cart-menu :show-text="true"></shopping-cart-menu>
                              </div>
                              <div
                                    class="menu-item-wrapper menu-item-wrapper-warenkorb menu-item-wrapper__cart menu-item-wrapper__cart-close"
                                    @click="handleCloseMenu"
                                    v-else-if="showMenu || showPoints"
                              >
                                    <img src="../../assets/img/icons/ic_clear.svg" alt="Schließen" />
                              </div>
                        </div>
                  </div>
            </section>

            <Categories v-show="showMenu" @categoryClick="categoryClick" @landingPageClick="landingPageClick" @onEnterProductSearch="searchProduct"></Categories>

            <div class="main-menu__sub-menu hidden lg:block" v-bind:class="{ 'no-shadow': showMenu }">
                  <div class="main-menu__sub-menu--item">
                        <p>Nicht genügend Punkte?</p>
                        <router-link :to="'/praemien/praemienaktionen/zuzahlung'">Einfach zuzahlen</router-link>
                  </div>
                  <div class="main-menu__sub-menu--item main-menu__sub-menu--item-right">
                        <div class="px-2"  @click="checkLogin">
                             <a>{{ t("LBL_MY_ORDERS") }}</a> 
                        </div>
                        <router-link :to="'/praemien/praemienaktionen/praemien-vorteile-services'" class="px-2">
                              {{ t("Vorteile & Services") }}
                        </router-link>
                        <a href="https://www.payback.at/einloesen" class="pl-4">Weitere Einlösemöglichkeiten</a>
                  </div>
            </div>

            <section class="page-content-lightestblue">
                  <div class="page-content-lightestblue__container" :class="{ 'pt-20': showPoints }">
                        <Chip :text="categorySelected" @close="clearCategoryFilterAction('true')" :closeable="true"></Chip>
                        <Chip :text="searchText" :data-rewardsshop-action="`search_for_${searchText}`" @close="clearSearchFilterAction()" :closeable="true"></Chip>
                        <Chip class="showPoints" :text="pointsFilter" @close="clearPointsFilterAction()" :closeable="true"></Chip>
                  </div>
            </section>
      </div>
</template>

<script>
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";
      import { computed, ref, onMounted, watch } from "vue";
      import InputSlider from "@/components/Inputs/InputSlider";
      import Categories from "@/components/Categories/Categories";
      import TextInput from "@/components/Inputs/TextInput";
      import Chip from "@/components/Chip/Chip";
      import ShoppingCartMenu from "@/components/ShoppingCart/ShoppingCartMenu";
      import { useRoute, useRouter } from "vue-router";
      import storage from "@/helpers/storage";
      import EventBus from "@/helpers/event-bus";
      //   import home from "@/views/Home";
      //   import { filter } from "minimatch";

      export default {
            name: "MainMenu",
            components: {
                  InputSlider,
                  Categories,
                  TextInput,
                  Chip,
                  ShoppingCartMenu
            },
            setup(props, { emit }) {
                  const { t } = useI18n();
                  const store = useStore();
                  const router = useRouter();
                  const route = useRoute();
                  const input = ref([200, 15000]);
                  const categorySelected = ref("");
                  const selectedCategoryObj = ref(null);
                  const searchText = ref(props.mobileMenuSearchText);
                  const sortSelected = ref("Sortieren");
                  const sliderMin = ref(0);
                  const sliderMax = ref(30000);
                  const defaultRange = ref([200, 30000]);
                  let updatedSliderValues = ref([200, "unendlich"]);
                  const pointsFilter = ref("");
                  const filters = ref({});
                  const mainMenu = ref(null);

                  const showMenu = ref(false);
                  const showPoints = ref(false);
                  const showSortMenu = ref(false);
                  const scrollTime = ref(1);
                  const showInfoPanel = ref(false);
                  const showSortingInfo = ref(false);
                  const pointsSlider = ref();

                  const allCategories = computed(() => {
                        return store.getters["catalog/getCategories"];
                  });

                  EventBus.on("layoutClicked", (arg) => {
                        if (arg === true) {
                              showSortMenu.value = false;
                        }
                  });

                  EventBus.on("getSearchText", (arg) => {
                        searchText.value = arg;
                        searchProduct(searchText.value);
                  });

                  EventBus.on("selectedCategoryData", (arg) => {
                        selectedCategoryObj.value = arg;
                  });

                  EventBus.on("footerCategoryClicked", (arg) => {
                        categoryClick(arg.category, arg.type, arg.toggleCall);
                  });

                  const toggleCatMenu = (value) => {
                        if (showMenu.value === false) {
                              scrollTime.value = 250;
                              const scrollToElement = mainMenu.value;
                              scrollToElement.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start"
                              });
                        } else {
                              scrollTime.value = 1;
                        }

                        setTimeout(() => {
                              showMenu.value = value == "toggle" ? !showMenu.value : false;
                              showSortMenu.value = false;
                              showPoints.value = false;
                              document.body.style.overflow = showMenu.value === false ? "" : "hidden";
                              document.querySelector(".menu-item-wrapper__category").style.background = showMenu.value === false ? "" : "#fff";
                        }, scrollTime.value);
                  };

                  const showPointsSlider = () => {
                        showPoints.value = !showPoints.value;
                        showMenu.value = false;
                        document.body.style.overflow = showMenu.value === false ? "" : "hidden";
                  };

                  const handleCloseMenu = () => {
                        showMenu.value = false;
                        showSortMenu.value = false;
                        showPoints.value = false;
                        document.body.style.overflow = showMenu.value === false ? "" : "hidden";
                        document.querySelector(".menu-item-wrapper__category").style.background = showMenu.value === false ? "" : "#fff";
                  };

                  const showSortMenuItems = () => {
                        showSortMenu.value = !showSortMenu.value;
                        showPoints.value = false;
                        EventBus.emit("layoutClicked", false);
                  };

                  const showInfo = () => {
                        showInfoPanel.value = !showInfoPanel.value;
                  };

                  const goToCart = () => {
                        store.dispatch("catalog/emptyProductsList");
                        router.push({
                              name: "shopping-cart",
                              params: {}
                        });
                  };

                  const isLoggedIn = store.getters["account/isLoggedIn"];


                  const checkLogin = () => {
                        if (isLoggedIn) {
                              router.push({ name: "my-orders" });
                        } else {
                              const data = {
                                    returnUrl: "/praemien/meine-bestellungen",
                                    loginType: "normal"
                              };
                              store.dispatch("auth/redirectToLoginPage", data);
                        }
                  };

                  if (storage.get("selected-category-id") > 0) {
                        filters.value["filterCategoryIds"] = storage.get("selected-category-id");
                        store.dispatch("catalog/setFilters", filters.value);
                        store.dispatch("catalog/getProductsList");
                  }
                  if (storage.get("selected-category-name")) {
                        categorySelected.value = storage.get("selected-category-name");
                  }

                  const initFilters = () => {
                        if (!filters.value["sortOrderField"]) {
                              sortSelected.value = "Bestseller";
                        }
                  };
                  const checkFilterOnLoad = async () => {
                        if (route?.meta?.pageType === "home") {
                              initFilters();
                              clearCategoryFilterAction();
                        } else if (route?.meta?.pageType === "category") {
                              let keyUrl = route.params?.category;
                              await store.dispatch("catalog/getCategory", keyUrl);
                              const category = computed(() => {
                                    return store.getters["catalog/getCategory"];
                              });
                              if (category.value) {
                                    categoryClick(category.value, category.value.parent_id, false);
                              }
                        }

                        // get query params from url
                        const routerQuery = router.currentRoute?.value?.query;
                        const querySearchParams = routerQuery?.searchTerm;
                        const querySortParamsOrder = routerQuery?.sortOrderField;
                        const querySortParamsDirection = routerQuery?.sortOrderDirection;
                        const queryFilterParams = [routerQuery?.filterPointsMin, routerQuery?.filterPointsMax];

                        // if there's search query
                        if (querySearchParams) {
                              searchText.value = querySearchParams;
                              await searchProduct(searchText.value);
                        }

                        // if there's sort query
                        if (querySortParamsOrder && querySortParamsDirection) {
                              await sortBy(querySortParamsOrder, querySortParamsDirection);
                        }

                        // if there's point's filter query
                        if (!isNaN(queryFilterParams[0])) {
                              pointsSlider.value.setDate(queryFilterParams);
                              sliderOutPut(queryFilterParams);
                              await sliderOutPutFetch(queryFilterParams);
                        }
                  };

                  const clearCategoryFilterAction = (trigger) => {
                        storage.remove("parent-category-id");
                        storage.remove("selected-subcategory-parent-id");
                        storage.remove("selected-category-type");
                        store.dispatch("catalog/emptyProductsList");
                        storage.set("selected-category-id", 0, Number);
                        storage.set("selected-category-name", "", String);
                        delete filters.value["filterCategoryIds"];
                        store.dispatch("catalog/setCategoryName", "");
                        store.dispatch("catalog/setFilters", filters);

                        const routerQuery = router.currentRoute?.value?.query;

                        const filterList = ["filterPointsMax", "filterPointsMin", "searchTerm", "sortOrderField", "sortOrderDirection"];

                        const queryParamsPresent = Object.keys(routerQuery).length > 0;

                        const queryParamContainsFilterKey = Object.keys(routerQuery).some((queryParam) => filterList.find((filter) => filter === queryParam));

                        // console.log(JSON.stringify(routerQuery).length + '->' + Object.keys(routerQuery).includes('filterPointsMin') + ' query- ' + querySearchParams + ' -query ' + queryFilterParams)
                        if (!(queryParamsPresent && queryParamContainsFilterKey)) {
                              getProductsList();
                        }

                        getMainPageContents();

                        categorySelected.value = "";
                        if (trigger === "true") {
                              router.replace({ path: "/", query: filters.value });
                        }
                  };

                  const clearPointsFilterAction = () => {
                        pointsFilter.value = "";
                        showPoints.value = false;
                        delete filters.value["filterPointsMin"];
                        delete filters.value["filterPointsMax"];
                        store.dispatch("catalog/emptyProductsList");
                        store.dispatch("catalog/setFilters", filters);
                        getProductsList();

                        router.replace({ query: filters.value });
                  };

                  const clearSearchFilterAction = () => {
                        showSortingInfo.value = false;
                        storage.set("q", "", String);
                        delete filters.value["searchTerm"];
                        if (parseInt(storage.get("selected-category-id")) > 0) {
                              filters.value["filterCategoryIds"] = storage.get("selected-category-id");
                        } else {
                              delete filters.value["filterCategoryIds"];
                        }

                        store.dispatch("catalog/emptyProductsList");
                        store.dispatch("catalog/setFilters", filters);
                        getProductsList();
                        store.dispatch("cms/getMainPageContents");
                        searchText.value = "";
                        router.replace({ query: filters.value });
                        // EventBus.emit("getSearchText", searchText.value);
                  };

                  const clearSortSelected = () => {
                        sortSelected.value = "Bestseller";
                        delete filters.value["sortOrderField"];
                        delete filters.value["sortOrderDirection"];
                        store.dispatch("catalog/emptyProductsList");
                        store.dispatch("catalog/setFilters", filters.value);
                        getProductsList();
                        router.replace({ path: route.params.filter, query: filters.value });
                  };

                  const categoryClick = (category, type, callToggleCatMenu = true) => {
                        storage.set("selected-category-id", category.id, Number);
                        storage.set("selected-category-name", category.name, String);
                        storage.set("selected-subcategory-parent-id", category.parent_id, Number);
                        storage.set("selected-category-type", type, String);
                        if (category.level === 2) {
                              storage.set("parent-category-id", category.id, Number);
                              storage.set("parent-category-name", category.name, Number);
                        } else if (category.level === 3) {
                              const parent = allCategories.value.children.find((pcategory) => pcategory.level === 2 && pcategory.id === category.parent_id);
                              storage.set("parent-category-id", parent.id, Number);
                              storage.set("parent-category-name", parent.name, Number);
                        }

                        store.dispatch("catalog/setCategoryName", category.name);
                        store.dispatch("sliderImages/setImages", { data: category, type: "CATEGORY" });
                        filters.value["filterCategoryIds"] = category.id;
                        store.dispatch("catalog/setFilters", filters.value);
                        getProductsList();
                        if (callToggleCatMenu) {
                              toggleCatMenu();
                        }

                        categorySelected.value = category.name;
                        EventBus.emit("selectedCategoryData", category);
                  };

                  const landingPageClick = (url) => {
                        if (url.includes("http")) {
                              window.location.assign(url);
                        } else {
                              let urlKey = url.split("/").pop();
                              router.push({
                                    name: "landing-page",
                                    params: { url_key: urlKey }
                              });
                        }
                  };

                  const searchProduct = async (input) => {
                        handleCloseMenu();
                        input.length > 0 ? (showSortingInfo.value = true) : (showSortingInfo.value = false);
                        filters.value["searchTerm"] = /\s/.test(input) ? input.trim() : input;
                        await store.dispatch("catalog/setFilters", filters.value);

                        await getProductsList();
                        searchText.value = input;
                        await router.replace({ path: route?.params?.filter, query: filters?.value });
                  };

                  const sliderOutPut = (value) => {
                        updatedSliderValues.value = value;
                        pointsFilter.value = `${updatdSliderMinValues.value} °P bis ${updatdSliderMaxValues.value}`;
                  };

                  const sliderOutPutFetch = async (value) => {
                        updatedSliderValues.value = value;
                        filters.value["filterPointsMin"] = Math.ceil(value[0] / 100) * 100;
                        filters.value["filterPointsMax"] = Math.ceil(value[1] / 100) * 100;
                        await store.dispatch("catalog/setFilters", filters.value);
                        await getProductsList();

                        if (value[1] == sliderMax.value) {
                              value[1] = "unendlich";
                        }
                        await router.replace({ path: route.params.filter, query: filters.value });
                  };

                  const updatdSliderMinValues = computed(() => {
                        return Math.ceil(updatedSliderValues.value[0] / 100) * 100;
                  });
                  const updatdSliderMaxValues = computed(() => {
                        return isNaN(updatedSliderValues.value[1]) ? updatedSliderValues.value[1] : Math.ceil(updatedSliderValues.value[1] / 100) * 100;
                  });

                  const sortBy = (sort, direction, text) => {
                        const validSortValues = ["price"];
                        const validDirectionValues = ["ASC", "DESC"];

                        if (!text) {
                              if (direction === "ASC") {
                                    sortSelected.value = "Preis aufsteigend";
                              } else if (direction === "DESC") {
                                    sortSelected.value = "Preis absteigend";
                              }
                        } else {
                              if (direction === "ASC" || direction === "DESC") {
                                    sortSelected.value = text;
                              }
                        }
                        if (validSortValues.includes(sort)) {
                              filters.value["sortOrderField"] = sort;
                        }
                        if (validDirectionValues.includes(direction)) {
                              filters.value["sortOrderDirection"] = direction;
                        }
                        store.dispatch("catalog/setFilters", filters.value);
                        if (text) {
                              showSortMenu.value = !showSortMenu.value;
                        }
                        getProductsList();
                        router.replace({ path: route.params.filter, query: filters.value });
                  };

                  const getProductsList = () => {
                        store.dispatch("catalog/getProductsList");
                  };
                  const getMainPageContents = () => {
                        store.dispatch("cms/getMainPageContents");
                  };

                  onMounted(async () => {
                        await store.dispatch("catalog/getCategories");
                        await checkFilterOnLoad();
                  });

                  watch(
                        () => route?.meta?.pageType === "home",
                        async () => {
                              await checkFilterOnLoad();
                        }
                  );

                  return {
                        filters,
                        categorySelected,
                        clearCategoryFilterAction,
                        clearSearchFilterAction,
                        clearPointsFilterAction,
                        searchText,
                        clearSortSelected,
                        sortSelected,
                        toggleCatMenu,
                        showPointsSlider,
                        showPoints,
                        pointsFilter,
                        searchProduct,
                        showMenu,
                        showSortMenu,
                        showInfo,
                        showInfoPanel,
                        showSortingInfo,
                        showSortMenuItems,
                        categoryClick,
                        landingPageClick,
                        sortBy,
                        sliderOutPut,
                        sliderOutPutFetch,
                        sliderMin,
                        sliderMax,
                        defaultRange,
                        updatedSliderValues,
                        updatdSliderMinValues,
                        updatdSliderMaxValues,
                        sliderInput: {
                              value: input,
                              format: function (value) {
                                    return `${Math.ceil(value / 100) * 100}`;
                              },
                              min: 0,
                              max: 15000
                        },
                        goToCart,
                        mainMenu,
                        scrollTime,
                        handleCloseMenu,
                        pointsSlider,
                        t,
                        checkLogin
                  };
            },
            props: {
                  docked: {
                        type: Boolean,
                        default: false
                  },
                  mobileMenuSearchText: {
                        type: String,
                        default: ""
                  }
            }
      };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
      .fake-nav-menu {
            height: 56px;
            width: 100%;
            background: #fff;
            position: relative;
            margin-top: 56px;
            & > div {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  max-width: 970px;
                  margin: 0 auto;
                  height: 100%;
                  .back-button div {
                        margin-left: 0.8rem;
                        flex-direction: row;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: #0046aa;
                        cursor: pointer;
                  }
            }
      }
      .main-menu-container {
            @apply w-9/12 ml-auto mr-auto justify-center items-center relative bg-lightblue-light1 z-100;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            min-width: 760px;
            &.no-shadow {
                  box-shadow: none;
            }
            @media (min-width: 992px) {
                  width: 960px;
            }
            @media (max-width: 768px) {
                  width: 100%;
                  min-width: 100%;
                  border-radius: 0;
                  margin-bottom: 0;
                  transform: none;
            }
            .menu-item-wrapper .menu-item__text {
                  @media screen and (max-width: 768px) {
                        font-size: 13px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-weight: 600;
                  }
                  @media screen and (max-width: 450px) {
                        font-size: 12px;
                  }
                  @media screen and (max-width: 380px) {
                        font-size: 11px;
                  }
            }
      }

      .page-content-lightestblue__container {
            @media (max-width: 768px) {
                  @apply justify-start items-start ml-2 flex;
            }

            @media (min-width: 768px) {
                  .showPoints {
                        display: none !important;
                  }
            }
      }
      .main-menu__sub-menu {
            @apply flex justify-between text-sm text-black mx-auto pt-2 pb-3;
            font-size: 0.875rem;

            @media (min-width: 992px) {
                  width: 960px;
                  max-width: 100%;
            }
            @media (max-width: 768px) {
                  @apply hidden;
            }
            &--item {
                  @apply inline-flex items-center justify-center;
                  p,
                  a {
                        display: inline-block;
                        @apply px-2;
                        cursor: pointer;
                        &:not(:first-of-type) {
                              @apply border-l border-black-lightest;
                        }
                  }
                  a {
                        @apply text-blue;
                  }
                  &-right {
                        @apply inline-flex items-center justify-center ml-4;
                        a {
                              display: inline-block;
                              @apply pl-4 text-sm;
                              &:not(:first-of-type) {
                                    @apply border-l border-black-lightest;
                              }
                        }
                  }
            }
            p {
                  margin-bottom: 0;
            }
      }
      .menu-item-wrapper {
            @apply text-black flex flex-col justify-center items-center pr-1.5 pl-1.5 z-10 border-r border-highlighted text-xs relative;
            height: 105px;
            font-size: 14px;
            &__category {
                  cursor: pointer;
                  img.category-menu {
                        position: relative;
                        top: 7px;
                  }
            }
            &__sort {
                  cursor: pointer;
            }
            &__cart {
                  cursor: pointer;
                  &-close {
                        @apply flex justify-center items-center;
                  }
            }
            .header-text {
                  @apply text-gray-dark text-xs self-start;
            }
            .sort-selected {
                  @apply text-blue-light text-center w-full text-xs relative;
                  top: 6px;
            }
            .sort-image {
                  @apply text-black rounded inline-flex items-center relative;
                  top: -2px;
            }
            &__points {
                  @media screen and (max-width: 768px) {
                        display: none;
                  }
            }
            &__points-mobile {
                  display: none;
                  @media screen and (max-width: 768px) {
                        display: flex;
                        img {
                              position: relative;
                              top: 6px;
                        }
                  }
            }
      }
      .points-mobile__slider {
            @apply absolute w-full left-0  py-8 px-10 bg-white z-10 shadow-md;
            top: 5.7rem;
            margin-top: -1px;
            z-index: 9999;
      }
      .sort-menu {
            @apply text-black bg-white border-blue-focus p-1 pt-2 absolute z-100 mt-18 shadow-sm;
            top: 30px;
            left: 0;
            min-width: 100%;
            white-space: nowrap;
            font-size: 90%;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 15%), 0px 0px 2px rgb(0 0 0 / 12%);
      }

      .docked {
            .main-menu-container {
                  @apply fixed top-0 left-0 w-full;
                  margin-bottom: 0;
                  border-radius: 0;

                  &__grid {
                        width: 960px;
                        align-self: center;
                        margin: 0 auto;
                        @media (max-width: 768px) {
                              width: 100vw;
                              min-width: 100%;
                        }
                  }
            }
            .menu-item-wrapper {
                  &__category {
                        border-left-width: 0px;
                        cursor: pointer;
                  }
            }
      }
      .show-info {
            padding: 1rem 1.25rem;
            margin: 0 auto;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            width: 960px;
            max-width: 100%;
            font-size: 0.85rem;
            line-height: 1.6rem;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
      }
      .showInfoMobile {
            @apply flex justify-between items-center;
      }
      .arrow {
            mask-image: url("../../assets/img/icons/ic_arrow_drop_down.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            mask-size: 26px 26px;
            mask-repeat: no-repeat;
            mask-position: center;

            -webkit-mask-size: 26px 26px;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
            height: 26px;
            width: 26px;
            @apply flex justify-center items-center bg-blue;
      }

      .up {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            transition: transform 0.3s;

            &.down {
                  transform: rotate(0deg);
                  -webkit-transform: rotate(0deg);
                  transition: transform 0.3s;
            }
      }
</style>
