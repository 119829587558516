import { useCartDefaultGetters } from '@/composables/useCartDefaultGetters'

export default {
  ...useCartDefaultGetters(),
  getLogger(state) {
    //@todo is this ever used?
    return state.logger;
  },
  getStepper(state) {
    return state.step;
  },
  totalNrOfItems(state, getters) {
    let itemsNr = 0;
    let cart = getters["cart"];
    if (cart.items && cart.items.length > 0) {
      for (let item in cart.items) {
        itemsNr += parseInt(cart.items[item].qty);
      }
    }

    return itemsNr;
  },
  getCartArrayIndex(state) {
    return state.cartArrayIndex
  }

}
