<template>
 <div id="app-default" @click="layoutClicked($event)">
  <Header />
  <shifty-bar v-if="isHome" />

  <div class="wrapper">
   <top-menu-mobile v-if="showMenu(landingPageData)" @getSearchText="getSearchText"></top-menu-mobile>
   <!-- Multiple images in header  -->
   <div class="slider" v-if="showSlider(landingPageData)">
    <div class="sliderHeader">
     <image-slider v-if="landingPageData" :fixedHeight="sliderHeight" :class="landingPageData.type === 'landing_page' && !isHome ? 'landingPageSlider' : ''" />
    </div>
   </div>

   <!-- one image in header  -->
   <div class="slider" v-if="showOneImage(landingPageData)">
    <image-header></image-header>
   </div>

   <div class="main-menu" v-if="showMenu(landingPageData)" ref="mainMenu">
    <main-menu :docked="docked" :mobileMenuSearchText="searchText" :key="searchText" />
   </div>
   <Spinner v-if="loading"></Spinner>
   <div class="main-page" ref="mainPage">
    <router-view v-if="isMounted" :key="$route.path" />
   </div>
  </div>
  <div class="bg-light category-footer-section" v-if="showMenu(landingPageData)">
   <CategoriesFooter v-if="isMounted && !loading" />
  </div>
  <div class="page-footer">
   <Footer />
  </div>
 </div>
</template>
<script>
 import Footer from "@/components/Footer/Footer";
 import Header from "@/components/Header/Header";
 import MainMenu from "../components/Menu/MainMenu";
 import ShiftyBar from "../components/ShiftyBar/ShiftyBar";
 import { useRoute, useRouter } from "vue-router";
 import { computed, onMounted, onUnmounted, ref, defineAsyncComponent, nextTick } from "vue";
 import EventBus from "@/helpers/event-bus";
 import TopMenuMobile from "../components/Menu/TopMenuMobile.vue";
 import Spinner from "../components/Spinner/Spinner.vue";
 import { useStore } from "vuex";
 import imageHeader from "@/components/ImageHeader/ImageHeader";
 import CategoriesFooter from "@/components/Categories/CategoriesFooter";

 const imageSlider = defineAsyncComponent({
  loader: () => import("@/components/ImageSlider/ImageSlider"),
  loadingComponent: Spinner
 });

 export default {
  components: {
   Header,
   imageSlider,
   imageHeader,
   Footer,
   ShiftyBar,
   Spinner,
   MainMenu,
   TopMenuMobile,
   CategoriesFooter
  },

  setup() {
   const route = useRoute();
   const mainMenu = ref(null);
   const mainPage = ref(null);
   const docked = ref(false);
   const searchText = ref("");
   const selectedCategoryData = ref(null);
   const router = useRouter();
   const store = useStore();
   let isMounted = ref(false);

   const isHome = computed(() => {
    return route?.meta?.pageType === "home";
   });

   EventBus.on("selectedCategoryData", (arg) => {
    selectedCategoryData.value = arg;
   });

   const windowSize = ref(window.innerWidth);

   onMounted(async () => {
    await store.dispatch("cart/get");
    window.addEventListener("resize", () => {
     windowSize.value = window.innerWidth;
    });
    if (route.meta.title === "Payback AT - 404") {
     router.push("/404");
    }
    window.document.body.onscroll = () => {
     const menutop = mainMenu?.value?.getBoundingClientRect().top;
     const pagetop = mainPage?.value?.getBoundingClientRect().top;

     if (menutop <= 0 && pagetop <= 154) {
      docked.value = true;
     } else {
      docked.value = false;
     }
    };
    isMounted.value = true;
   });
   onUnmounted(() => {
    window.removeEventListener("resize", () => {
     windowSize.value = window.innerWidth;
    });
   });

   const images = computed(() => {
    return store.getters["sliderImages/getImages"];
   });

   const showSlider = (landingPageData) => {
    let routeParts = route.path.split("/");
    if (routeParts.length > 0) {
     return route.meta.pageType !== "punkte-einloesen" && route.meta.title !== "Payback AT - 404" && route.path.indexOf("praemien") === -1 && route.path.indexOf("bargeld") === -1;
    }
   };

   const showOneImage = (landingPageData) => {
    let routeParts = route.path.split("/");
    if (routeParts.length > 0) {
     return (
      (route.path.indexOf("praemien") >= 0 && route.path.indexOf("produkte") >= 0 && route.meta.title !== "Payback AT - 404") ||
      (route.path.indexOf("praemien") >= 0 && route.path.indexOf("kategorie") >= 0) ||
      (route.path.indexOf("praemien") >= 0 && route.path.indexOf("suche") >= 0) ||
      (route.path.indexOf("praemienaktionen") >= 0 && landingPageData.type != "content_page" && route.meta.loggerType !== "pages.manufacturers")
     );
    }
   };

   const showMenu = (landingPageData) => {
    //  <!-- Hiding the menu for landing page untill we finalise the requirement -->
    landingPageData.showMenu = false;
    let routeParts = route.path.split("/");
    if (routeParts.length > 0) {
     return (
      (route.meta.pageType !== "punkte-einloesen" && route.meta.title !== "Payback AT - 404" && route.path.indexOf("praemien") === -1 && route.path.indexOf("bargeld") === -1) ||
      (route.path.indexOf("praemienaktionen") >= 0 && (landingPageData ? landingPageData.showMenu : false)) ||
      (route.path.indexOf("praemien") >= 0 && route.path.indexOf("produkte") >= 0) ||
      (route.path.indexOf("praemien") >= 0 && route.path.indexOf("kategorie") >= 0) ||
      (route.path.indexOf("praemien") >= 0 && route.path.indexOf("suche") >= 0)
     );
    }
   };
   const layoutClicked = (arg) => {
    if (arg && arg.target && typeof arg.target.className != "object") {
     let exception = arg.target.className.indexOf("sort-image") === -1 && arg.target.className.indexOf("menu-item-wrapper__sort") === -1;
     EventBus.emit("layoutClicked", exception);
    }
   };

   let loading = computed(() => {
    return store.getters["apiLoading/getLoading"];
   });

   const landingPageData = computed(() => {
    return store.getters["catalog/getLandingPage"];
   });

   const sliderHeight = computed(() => {
    if (windowSize.value > 960) {
     return parseInt("260");
    }
    if (windowSize.value > 767) {
     return 160;
    }
    return 160;
   });

   const getSearchText = (text) => {
    searchText.value = text;
   };

   const checkPageType = () => {
    return route.meta.pageType === "category" ? "category" : route.meta.pageType === "lannding-page" ? "landing-page" : "";
   };
   onMounted(() => {});
   return {
    showSlider,
    showOneImage,
    images,
    showMenu,
    layoutClicked,
    getSearchText,
    searchText,
    mainMenu,
    mainPage,
    docked,
    windowSize,
    sliderHeight,
    loading,
    landingPageData,
    selectedCategoryData,
    checkPageType,
    isMounted,
    isHome
   };
  }
 };
</script>

<style lang="postcss">
 .wrapper {
  @apply bg-white;
 }

 .main-menu {
  @apply z-10 w-full bg-blue-lightest;
 }
 .slider {
  z-index: 1;
  margin-bottom: 0rem;

  @media screen and (max-width: 767px) {
   margin-bottom: 0rem;
  }
 }

 .page--main-nav-menu {
  @apply z-10 -mt-10 bg-blue-lightest;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }
 }

 .page-nav-menu {
  @apply bg-white;
  position: relative;
  z-index: 999;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }

  @media (max-width: 767px) {
   &__container {
    @apply w-full;
   }
  }
 }

 .page-content-white {
  @apply bg-white;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }
 }

 .page-content-blue {
  @apply text-black;
  background: #bfd1e9;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }
 }

 .page-content-lightblue {
  @apply bg-blue-lighter text-black-light;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }
 }

 /* Generic Css */

 .page-content-lightestblue-main {
  @apply text-black-light;
  &__container {
   h2 {
    @apply pt-5;
    @media screen and (max-width: 767px) {
     @apply mx-2;
    }
   }
   @apply grid grid-cols-3 gap-4 grid-flow-row-dense text-left w-9/12 mx-auto my-0 max-w-full justify-center items-center;
   &-colLeft {
    @apply col-span-1;
    @media (max-width: 600px) {
     @apply col-span-1;
    }
   }
   &-colRight {
    @apply col-span-2;
   }
   @media (min-width: 992px) {
    @apply grid-cols-3;
    width: 960px;
   }
   @media (max-width: 800px) {
    @apply grid-cols-1;
   }
  }
 }

 .page-content-lightestblue {
  @apply bg-blue-lightest text-black-light;
  &__container {
   @apply w-11/12 ml-auto mr-auto justify-center items-center;
   h2 {
    @apply mt-5;
    @media screen and (max-width: 767px) {
     @apply mx-2;
    }
   }
   &__table {
    @apply grid grid-cols-3 gap-4 grid-flow-row-dense  text-left;

    &-colLeft {
     @apply col-span-2;

     @media (max-width: 600px) {
      @apply col-span-1;
     }
    }
    &-colRight {
     @apply col-span-1 mb-20 relative;
     height: 100%;
    }

    @media (min-width: 992px) {
     @apply grid-cols-3;
    }

    @media (max-width: 800px) {
     @apply grid-cols-1;
    }
    @media (max-width: 767px) {
     @apply gap-0;
    }
   }
  }
  @media (min-width: 992px) {
   &__container {
    width: 960px;
   }
  }
 }

 .page-footer-white {
  @apply bg-white;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }
 }

 .page-footer-lightblue {
  @apply bg-blue-lighter text-black-light mt-6 mb-6 font-bold;
  &__container {
   @apply w-9/12 ml-auto mr-auto justify-center items-center;
  }
  @media (min-width: 992px) {
   &__container {
    @apply max-w-screen-md;
   }
  }
 }

 .product-container-wrapper {
  @apply text-black  px-2 py-2 rounded-md m-2;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
  background-color: #fff;
  cursor: pointer;
  padding: 5px;
  border: none;
  &:hover {
   box-shadow: inset 0 0 0 5px #bfcbdd;
  }
  &.red {
   &:hover {
    box-shadow: inset 0 0 0 5px #c1002b;
   }
  }
 }

 .sliderHeader .vueperslide {
  margin: 0 auto;
  cursor: pointer;
 }
 .landing-page .sliderHeader .vueperslide {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
 }
 .landingPageSlider {
  width: 960px;
  margin: 0 auto;
 }
 .landingPageSlider .vueperslide {
  height: 260px;
 }
 @media (max-width: 767px) {
  .landingPageSlider {
   width: 100%;
   max-width: max-content;
   height: auto;
  }
 }
</style>
