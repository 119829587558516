import storage from "@/helpers/storage";
import constants from "@/helpers/constants"
import { isObject } from 'lodash-es'

export default {
    ping() {
      let ping = storage.get(constants.STORAGE_KEYS.PING, Object);

      if (isObject(ping)) {
        return ping
      }

      return null;
    },
    account(state, getters) {
      const ping = getters['ping']
      if(ping){
        let customer = ping.customer
        if (customer &&
            customer.addresses &&
            customer.addresses.billing &&
            customer.addresses.shipping) {
          return customer
        }
      }

      return state.account;
    },
    shippingAddress(state, getters) {
      const account = getters['account']

      if (account && account.addresses && account.addresses.shipping) {
        let shippingAddress = account.addresses.shipping
          // refactor salutation to be 0 according to #18938933 - PB AT: Salutation
          // shippingAddress.prefix = account.salutation;
          shippingAddress.prefix = 0;
        return shippingAddress
      }
      return {};
    },
    billingAddress(state, getters) {
      const account = getters['account']

      if (account && account.addresses && account.addresses.billing) {
        let billingAddress = account.addresses.billing
          // billingAddress["prefix"] = account.salutation;
          billingAddress["prefix"] = 0;
        return billingAddress
      }
      return {};
    },
    email(state, getters) {
      const account = getters['account']

      return account.email ? account.email : ""
    },
    isLoggedIn(state, getters){
      return state.isLoggedIn
    }
}
