import tService from "@/service/tService";
export default class tErrorInformationService {
    constructor() {
        this.tService = new tService();
        this.error = {
            errorID: undefined,
            errorName: undefined
        };
    }
    init() {
        this.tService.init();
    }
    setErrorValues(errorId, errorName) {
        this.error.errorID = String(errorId);
        this.error.errorName = String(errorName);
    }
    get() {
        return error;
    }
    submit() {
        this.tService.setPageInfo(this.error, true);
        this.tService.setPageName('error_page')
        this.tService.setCategory({
            primaryCategory: "rewards",
            subCategory: "error",
        });
        this.tService.track();
    }
}