import storage from '@/helpers/storage'
import tCartInformationService from "@/service/tCartInformationService";

export function useCartDefaultMutations() {
  return {
    setCart(state, cart) {
      state.cart = cart;

      let start = storage.get("start", Object)

      if (start !== null) {
        start.cart = cart;
        storage.set("start", start, Object);
      }
    },
    async trackCart(state, products) {
      const tCrtInformationService = new tCartInformationService();
      tCrtInformationService.trackCart(products);
    },
  }
}