<template xmlns="http://www.w3.org/1999/html">
      <div class="main-menu-cat transition-opacity ease-in-out" v-if="categories?.children">
            <div class="mobile__search p-4 col-span-full block sm:hidden">
                  <text-input @onEnter="searchProduct" :value="searchText" :placeholder="'Prämien, Marken, ...'"
                        cssClass="searchinput"></text-input>
            </div>
            <div class="main-menu-cat__grid grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-2">
                  <div class="main-menu-cat__grid-cols col-span-1 md:col-span-1 lg:col-span-4">
                        <ul class="list-none main-menu-cat__items" v-for="category in categories.children" :key="category.id">
                              <li :id="category.name" class="main-menu-cat__item hidden md:block"
                                    :class="[{ dropdown: category.children.length > 0 }, { 'text-black ': category.id == active }]"
                                    @mouseover="handleSubCats(category)">
                                    <div class="grid main-menu-cat__item-grid gap-2">
                                          <div class="col-span-3" @mouseover="handleSubCats(category)">
                                                <router-link :to="`/praemien/kategorie/${category.url_key}`"
                                                      :title="category.name"
                                                      @click="$emit('categoryClick', category, 'parentCategory')"
                                                      @mouseover="handleSubCats(category)">
                                                      <div class="category-icon" :style="getIconStyle(category)"></div>
                                                      {{ category.name }}
                                                </router-link>
                                          </div>
                                          <div class="flex justify-end items-center pr-2">
                                                <img v-if="category.children.length > 0"
                                                      class="text-black rounded inline-flex items-center main-menu-cat__item-dropdown--chevron"
                                                      src="../../assets/img/icons/chevron_right_24px.svg" alt="Unterkategorie"
                                                      :class="category.id == active ? 'show' : 'hide'" />
                                          </div>
                                    </div>
                              </li>
                              <li id="category.name" class="main-menu-cat__item block md:hidden"
                                    :class="[{ dropdown: category.children.length > 0 }, { 'text-black font-semi-bold': category.id == active }]"
                                    @click="handleSubCats(category)">
                                    <div class="grid grid-cols-3 gap-2">
                                          <div class="col-span-2">
                                                <router-link :to="`/praemien/kategorie/${category.url_key}`"
                                                      :title="category.name"
                                                      @click="$emit('categoryClick', category, 'parentCategory')">
                                                      <div class="category-icon" :style="getIconStyle(category)"></div>
                                                      {{ category.name }}
                                                </router-link>
                                          </div>
                                          <div class="main-menu-cat__item-dropdown--chevron-container">
                                                <img v-if="category.children.length > 0"
                                                      class="text-black rounded inline-flex items-center main-menu-cat__item-dropdown--chevron"
                                                      src="../../assets/img/icons/chevron_right_24px.svg" alt="Unterkategorie"
                                                      :class="category.id == active ? 'show' : 'hide'" />
                                          </div>
                                    </div>
                              </li>
                              <!--          subcat content-->
                              <div class="mobile block md:hidden" :class="category.id == active ? 'show' : 'hide'">
                                    <div class="main-menu-cat__grid-cols col-span-3" v-if="subCategories.length !== 0">
                                          <aside class="main-menu-cat__item-dropdown-item">
                                                <div class="font-semi-bold">
                                                      <router-link :to="`/praemien/kategorie/${category.url_key}`"
                                                            :title="category.name"
                                                            @click="$emit('categoryClick', category, 'parentCategory')">
                                                            <div class="category-icon" :style="getIconStyle(category)"></div>
                                                            Alle {{ category.name }}
                                                      </router-link>
                                                </div>
                                          </aside>

                                          <aside class="main-menu-cat__item-dropdown-item"
                                                v-for="subCategory in subCategories" :key="subCategory.id">
                                                <div @click="$emit('categoryClick', subCategory, 'subCategory')">
                                                      <router-link :to="`/praemien/kategorie/${subCategory.url_key}`"
                                                            :title="subCategory.name">
                                                            {{ subCategory.name }}
                                                      </router-link>
                                                </div>
                                          </aside>
                                          <div class="main-menu-cat__item-dropdown-item--brands-heading"
                                                v-if="brandImages.length > 0">
                                                <h4 class="">Beliebte Marken</h4>
                                          </div>
                                          <div class="main-menu-cat__item-dropdown-item--brands-brands">
                                                <a v-for="brand in brandImages" :key="brand.name" :href="brand.url"
                                                      :title="brand.name">
                                                      <div class="category-image p-4 bg-white">
                                                            <img v-if="brand.logo" :src="brand.logo" :alt="brand.name" />
                                                      </div>
                                                </a>
                                          </div>
                                    </div>
                                    <div class="col-span-6 main-menu-cat__grid-cols" v-if="categoryImages.length > 0">
                                          <div class="main-menu-right-links-wrapper" v-for="categoryImage in categoryImages"
                                                :key="categoryImage.fileName">
                                                <div class="main-menu-cat-header">
                                                      <a :title="categoryImage.fileName"
                                                            @click="$emit('landingPageClick', categoryImage.url)">
                                                            <div class="category-image">
                                                                  <picture>
                                                                        <source media="(min-width:768px)"
                                                                              :srcset="categoryImage.fileName" />
                                                                        <img v-if="categoryImage.mobileFileName"
                                                                              :src="categoryImage.mobileFileName"
                                                                              :alt="categoryImage.mobileFileName" />
                                                                        <img v-else :src="categoryImage.fileName" />

                                                                  </picture>
                                                            </div>
                                                      </a>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </ul>
                  </div>

                  <div class="main-menu-cat__grid-cols col-span-8"
                        v-if="categoryImages.length === 0 && subCategories.length === 0">
                        <div class="main-menu-right-links-wrapper"
                              v-for="mainCategoryImage in rightSideLinks" :key="mainCategoryImage.order">
                              <div class="main-menu-cat-header">
                                    <a :href="mainCategoryImage.url" :title="mainCategoryImage.mouseOver">
                                          <div class="category-image">
                                                <picture>
                                                      <source media="(min-width:768px)" :srcset="mainCategoryImage.image" />
                                                      <img :src="mainCategoryImage.imageMobile || mainCategoryImage.image" :alt="mainCategoryImage.mouseOver" />
                                                </picture>
                                          </div>
                                    </a>
                              </div>
                        </div>
                  </div>
                  <div class="main-menu-cat__grid-cols col-span-4 desktop hidden md:block relative" :style="getTop()"
                        v-if="subCategories.length !== 0">
                        <aside class="main-menu-cat__item-dropdown-item border-b border-highlighted"
                              v-for="subCategory in subCategories" :key="subCategory.id">
                              <div>
                                    <router-link :to="`/praemien/kategorie/${subCategory.url_key}`" :title="subCategory.name"
                                          @click="$emit('categoryClick', subCategory, 'subCategory')">
                                          {{ subCategory.name }}
                                    </router-link>
                              </div>
                        </aside>
                        <div class="main-menu-cat__item-dropdown-item--brands-heading desktop" v-if="brandImages.length > 0">
                              <h4 class="">Beliebte Marken</h4>
                        </div>
                        <div class="main-menu-cat__item-dropdown-item--brands-brands desktop" v-if="brandImages.length > 0">
                              <a v-for="brand in brandImages" :key="brand.name" :href="brand.url" :title="brand.name">
                                    <div class="category-image">
                                          <img v-if="brand.logo" :src="brand.logo" :alt="brand.name" />
                                    </div>
                              </a>
                        </div>
                  </div>

                  <div class="main-menu-cat__grid-cols col-span-4 desktop relative" :style="getTop()"
                        v-if="categoryImages.length > 0" :class="{ 'col-span-8': subCategories.length === 0 }">
                        <div class="main-menu-right-links-wrapper" v-for="categoryImage in categoryImages"
                              :key="categoryImage.fileName">
                              <div class="main-menu-cat-header">
                                    <a :title="categoryImage.mouseOver" @click="$emit('landingPageClick', categoryImage.url)">
                                          <div class="category-image">
                                                <picture>
                                                      <source media="(min-width:768px)" :srcset="categoryImage.fileName" />
                                                      <img v-if="categoryImage.mobileFileName"
                                                            :src="categoryImage.mobileFileName"
                                                            :alt="categoryImage.mobileFileName" />
                                                      <img v-else :src="categoryImage.fileName" />

                                                </picture>
                                          </div>
                                    </a>
                              </div>
                        </div>
                  </div>
                  <div class="main-menu-cat__grid-cols col-span-4 desktop relative" :style="getTop()"
                        v-if="categoryImages.length === 0">
                        <div class="main-menu-right-links-wrapper"
                              v-for="mainCategoryImage in rightSideLinks.mainCategoryImage" :key="mainCategoryImage.id">
                              <div class="main-menu-cat-header">
                                    <a :href="mainCategoryImage.url" :title="mainCategoryImage.mouseover">
                                          <div class="category-image">
                                                <picture>
                                                      <source media="(min-width:768px)" :srcset="mainCategoryImage.image" />
                                                      <img v-if="mainCategoryImage.imageMobile"
                                                            :src="mainCategoryImage.imageMobile"
                                                            :alt="mainCategoryImage.imageMobile" />
                                                      <img v-else :src="mainCategoryImage.fileName" />

                                                </picture>
                                          </div>
                                    </a>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import TextInput from "@/components/Inputs/TextInput";
import constants from "@/helpers/constants";

export default {
      emits: ["categoryClick"],
      name: "Categories",
      computed: {
        constants() {
          return constants
        },
            cssVars(source) {
                  return {
                        "--mask-src": require(source)
                  };
            }
      },
      components: { TextInput },
      setup(props, { emit }) {
            const store = useStore();

            store.dispatch("cms/getRightSideLinks");
            const subCategories = ref([]);
            const categoryImages = ref([]);
            const brandImages = ref([]);
            const active = ref(null);
            const categoryPosition = ref("");
            const searchText = ref();
            const categories = computed(() => {
                  return store.getters["catalog/getCategories"];
            });

            const rightSideLinks = computed(() => {
                  return store.getters["cms/getRightSideLinks"];
            });

            const getTop = () => {
                  return {
                        top: categoryPosition.value + "px"
                  };
            };
            const getIconStyle = (category) => {
                  return {
                        "background-size": "contain",
                        "background-position": "center",
                        "background-repeat": "no-repeat",

                        "mask-image": "url(" + category.category_icon + ")",

                        "mask-size": "20px 20px",
                        "mask-repeat": "no-repeat",
                        "mask-position": "center",

                        "-webkit-mask-image": "url(" + category.category_icon + ")",
                        "-webkit-mask-size": "20px 20px",
                        "-webkit-mask-repeat": "no-repeat",
                        "-webkit-mask-position": "center"
                  };
            };

            const handleSubCats = (category) => {
                  const selectedCategory = document.getElementById(category.name);
                  categoryPosition.value = selectedCategory.offsetTop - "35";

                  let id = category.id;
                  let cats = category.children;

                  if (active.value == id) {
                        active.value = null;
                  } else {
                        active.value = id;
                  }
                  subCategories.value = cats;

                  if (category.category_images) {
                        categoryImages.value = category.category_images;
                  } else {
                        categoryImages.value = [];
                  }
                  if (category.category_brands) {
                        brandImages.value = category.category_brands;
                  } else {
                        brandImages.value = [];
                  }
            };
            const searchProduct = (input) => {
                  emit("onEnterProductSearch", input);
            };
            return {
                  active,
                  getIconStyle,
                  getTop,
                  subCategories,
                  handleSubCats,
                  categoryImages,
                  brandImages,
                  searchProduct,
                  categories,
                  rightSideLinks,
                  categoryPosition,
                  searchText
            };
      }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
.main-menu-cat {
      @apply w-full ml-auto mr-auto justify-center items-center absolute text-blue-light bg-white pt-5 pb-10;
      z-index: 99;
      max-height: calc(100vh - 90px);
      overflow-y: auto;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;

      .font-semi-bold {
            font-weight: 600;
      }

      &__grid {
            width: 75%;
            min-width: 760px;

            margin: 0 auto;

            @media (min-width: 992px) {
                  width: 960px;
                  padding-top: 1rem;
            }

            @media (max-width: 991px) {
                  width: 100%;
                  min-width: 100%;
                  padding-top: 1rem;
            }

            &-cols {
                  @media (max-width: 767px) {
                        @apply col-span-4;
                  }

                  &.sub-category-image {
                        @apply flex justify-center p-5 relative;
                        top: -10px;

                        img {
                              width: 100%;
                              height: auto;
                        }

                        @media (max-width: 768px) {
                              @apply bg-lightblue-lightest;
                        }
                  }
            }
      }

      &__items {
            max-width: 100vw;
      }

      &__item {
            @apply pl-4 cursor-pointer py-4;
            font-size: 14px;
            line-height: 20px;
            min-height: 54px;

            &-grid {
                  @apply grid gap-2;
                  grid-template-columns: repeat(4, minmax(24px, 1fr));
            }

            &.dropdown {
                  img {
                        height: 12px;
                        width: auto;
                        position: relative;
                        top: -3px;
                  }

                  @media (max-width: 768px) {
                        &>.grid>.col-span-2>a {
                              pointer-events: none;
                        }

                        img {
                              transform: rotate(90deg);
                              transition: transform 0.2s;
                              transition-timing-function: ease-in;

                              &.show {
                                    transform: rotate(-90deg);
                              }
                        }
                  }
            }

            &-dropdown--chevron-container {
                  display: flex;
                  justify-content: flex-end;
                  padding-right: 20px;
                  align-items: center;
            }

            &-dropdown-item {
                  @apply text-blue-light px-4;
                  height: 50px;

                  &>div {
                        @apply w-full h-full flex items-center;

                        @media (max-width: 768px) {
                              @apply px-16 py-0;
                        }

                        &:not(:last-of-type) {
                              border-bottom: none;
                        }
                  }

                  &:hover,
                  &:focus {
                        @apply bg-lightblue-lightest;
                  }

                  &--brands-heading {
                        @apply p-4 pb-0;

                        h4 {
                              @apply text-black;
                              font-weight: 600;
                        }

                        @media (max-width: 767px) {
                              @apply bg-lightblue-lightest;
                        }
                  }

                  &--brands-brands {
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                        grid-gap: 20px;
                        @apply p-4;

                        @media (max-width: 768px) {
                              @apply bg-lightblue-lightest;
                        }

                        .category-image {
                              border-radius: 5px;
                              @apply border border-highlighted flex items-center justify-center;
                        }
                  }

                  @media (max-width: 768px) {
                        @apply bg-lightblue-lightest;
                  }
            }

            &:hover,
            &:focus {
                  @apply bg-lightblue-lightest text-black;
                  letter-spacing: 0rem;
                  font-weight: 600;

                  .category-icon {
                        @apply bg-black;
                  }
            }

            a {
                  display: grid;
                  align-items: center;
                  max-width: 100%;
                  word-break: break-word;
                  grid-template-columns: 40px 1fr;
                  grid-column-gap: 1px;

                  .category-icon {
                        @apply bg-blue;
                        width: 50px;
                        height: 25px;
                        padding: 1px;

                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;

                        mask-size: 20px 20px;
                        mask-repeat: no-repeat;
                        mask-position: center;

                        -webkit-mask-size: 20px 20px;
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                  }
            }
      }

      .main-menu-right-links-wrapper {
            @apply block w-full ml-auto mr-auto bg-white text-lightblue;

            .main-menu-cat-header {
                  @apply w-full mt-0;

                  @media (max-width: 767px) {
                        @apply px-4;
                  }
            }

            .highlighted {
                  @apply bg-red-light text-red-dark;
            }

            .category-image {
                  cursor: pointer;
                  @apply p-2;

                  img {
                        width: auto;
                        height: auto;
                        margin: 0 auto;
                        overflow-wrap: break-word;
                        @apply  rounded-md;
                        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.1);
                  }

                  @media (max-width: 768px) {
                        @apply bg-lightblue-lightest;
                  }
            }
      }
}

.docked .main-menu-cat {
      position: fixed;
      top: 90px;
}

ul {
      list-style: none;

      li {
            padding-top: 10px;
      }
}

.desktop {
      display: block;
      height: fit-content;
}

.main-menu-cat__item-dropdown-item--brands-brands.desktop {
      display: grid;
}

.main-menu-cat__grid-cols.sub-category-image.desktop {
      display: flex;
}

.mobile {
      display: none;
}

@media (max-width: 768px) {
      .mobile {
            display: none;

            &.show {
                  display: block;
            }

            &.hide {
                  display: none;
            }
      }

      .desktop,
      .main-menu-cat__grid-cols.sub-category-image.desktop {
            display: none;
      }
}

.mobile.show aside:not(:last-of-type)>div {
      @apply border-b border-highlighted;
}

.mobile__search {
      width: auto;
}
</style>
