<template>
      <section>
            <section class="page-footer-lightblue">
                  <div class="page-footer-lightblue__container">
                        <div class="page-footer-lightblue__container-content">
                              <div class="page-footer-lightblue__container-content__table">
                                    <div>
                                          <div class="page-footer-lightblue__container-content__table-content">
                                                <div>
                                                      <img src="../../assets/img/versandkostenfrei-icon-data.svg" alt="Versandkostenfrei" />
                                                </div>
                                                <h3>{{ t("LBL_FREE_SHIPPING") }}</h3>
                                          </div>

                                          <!--  -->
                                    </div>
                                    <div>
                                          <div class="page-footer-lightblue__container-content__table-content">
                                                <div>
                                                      <img src="../../assets/img/rueckgabe-icon-data.svg" alt="Kostenlose Rückgabe" />
                                                </div>
                                                <h3>{{ t("LBL_FREE_RETURNS") }}</h3>
                                          </div>
                                    </div>
                                    <div>
                                          <div class="page-footer-lightblue__container-content__table-content">
                                                <div>
                                                      <img src="../../assets/img/sicher-einkaufen-icon-data.svg" alt="Sicher einkaufen" />
                                                </div>
                                                <h3>{{ t("LBL_SHOP_SAFELY") }}</h3>
                                          </div>
                                    </div>
                                    <div>
                                          <div class="page-footer-lightblue__container-content__table-content">
                                                <div>
                                                      <img src="../../assets/img/rabatt-icon-data.svg" alt="bis zu 50% unter UVP" />
                                                </div>
                                                <h3>
                                                      {{
                                                            t("LBL_UP_TO_PERCENTAGE_BELOW_THE_RRP", {
                                                                  percentage: 50
                                                            })
                                                      }}
                                                </h3>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="page-footer-white">
                  <div class="page-footer footer page-footer-white__container">
                        <div class="footer-container page-footer-white__container-content">
                              <div class="page-footer-white__container-content__table2 justify-center text-left">
                                    <div class="page-footer-white__container-content__table2-content safe-payment">
                                          <div class="content-wrapper">
                                                <h3 class="border-b border-highlighted mb-2">
                                                      {{ t("LBL_PAY_SAFELY") }}
                                                </h3>
                                                <div class="grid grid-cols-4 gap-2 grid-flow-row-dense">
                                                      <div class="card-image-wrapper-footer">
                                                            <img class="card-image-footer" src="../../assets/img/amex-data.svg" alt="American Express" />
                                                      </div>
                                                      <div class="card-image-wrapper-footer">
                                                            <img class="card-image-footer" src="../../assets/img/mastercard-data.svg" alt="MasterCard" />
                                                      </div>
                                                      <div class="card-image-wrapper-footer">
                                                            <img class="card-image-footer" src="../../assets/img/visa-data.svg" alt="Visa" />
                                                      </div>
                                                      <div class="card-image-wrapper-footer">
                                                            <img class="card-image-footer" src="../../assets/img/paypal-data.svg" alt="PayPal" />
                                                      </div>
                                                      <div class="card-image-wrapper-footer">
                                                            <img class="card-image-footer" src="../../assets/img/klarna-data.svg" alt="Sofortüberweisung" />
                                                      </div>
                                                </div>
                                          </div>
                                          <!--  -->
                                    </div>
                                    <div class="page-footer-white__container-content__table2-content service-help">
                                          <div class="content-wrapper">
                                                <h3 class="border-b border-highlighted mb-2">
                                                      {{ t("LBL_SERVICE_AND_HELP") }}
                                                </h3>
                                                <ul>
                                                      <li class="border-b border-highlighted" @click="checkLogin">
                                                            <a class="cursor-pointer" :title="t('LBL_MY_ORDERS')" target="_blank"
                                                                  ><img src="@/assets/img/icons/ic_shoppinglist_footer.svg" alt="icon" class="list-icon" />{{ t("LBL_MY_ORDERS") }}
                                                            </a>
                                                      </li>
                                                      <li class="border-b border-highlighted">
                                                            <router-link to="/praemien/praemienaktionen/versand-lieferung" target="_blank" :title="t('LBL_DELIVERY_AND_SHIPPING')">
                                                                  <img src="@/assets/img/icons/ic_shipping_footer.svg" alt="icon" class="list-icon" />{{ t("LBL_DELIVERY_AND_SHIPPING") }}
                                                            </router-link>
                                                      </li>

                                                      <li class="border-b border-highlighted">
                                                            <router-link to="/praemien/praemienaktionen/widerrufsbelehrung" target="_blank" :title="t('LBL_RIGHT_OF_WITHDRAWAL')">
                                                                  <img src="@/assets/img/icons/ic_legal.svg" alt="icon" class="list-icon" />{{ t("LBL_RIGHT_OF_WITHDRAWAL") }}
                                                            </router-link>
                                                      </li>
                                                      <li class="border-b border-highlighted">
                                                            <router-link to="/praemien/praemienaktionen/hersteller" target="_blank" :title="t('LBL_MANUFACTURER_CONTACT')">
                                                                  <img src="@/assets/img/icons/ic_service_center.svg" alt="icon" class="list-icon" />{{ t("LBL_MANUFACTURER_CONTACT") }}
                                                            </router-link>
                                                      </li>
                                                      <li class="mb-2">
                                                            <router-link
                                                                  to="/praemien/praemienaktionen/hinweise-zum-datenschutz"
                                                                  target="_blank"
                                                                  :title="t('LBL_DATA_PROTECTION_INFORMATION_PREMIUM_SHOP')"
                                                            >
                                                                  <img src="@/assets/img/icons/ic_shield_check.svg" alt="icon" class="list-icon" />{{
                                                                        t("LBL_DATA_PROTECTION_INFORMATION_PREMIUM_SHOP")
                                                                  }}
                                                            </router-link>
                                                      </li>
                                                </ul>
                                          </div>
                                    </div>
                                    <div class="page-footer-white__container-content__table2-content">
                                          <div class="content-wrapper">
                                                <h3 class="border-b border-highlighted mb-2">
                                                      {{ t("LBL_SELLING_AND_SHIPPING_BY") }}
                                                </h3>
                                                <img class="logo" src="../../assets/img/logo_cadooz.svg" alt="Cadooz" />
                                          </div>
                                          <div class="content-wrapper">
                                                <h3 class="border-b border-highlighted mb-2">
                                                      {{ t("LBL_FOOTER_COUPON") }}
                                                </h3>
                                                <img class="logo max-h-full h-11" src="../../assets/img/pb_logo--desktop.svg" alt="Gutscheine" />
                                          </div>
                                          <div class="content-wrapper">
                                                <h3 class="border-b border-highlighted mb-2">
                                                      {{ t("LBL_FREE_SHIPPING") }}
                                                </h3>
                                                <img class="logo" src="../../assets/img/logo_responsive.png" alt="" />
                                          </div>
                                    </div>
                              </div>

                              <div class="grid grid-cols-4 gap-2 text-left text-blue xs:text-left py-2 border-t border-highlighted mt-50 footer-links-container">
                                    <div class="flex col-span-4 gap-2 footer-links">
                                          <router-link to="/praemien/praemienaktionen/praemien-agb" target="_blank" :title="t('LBL_PREMIUM_TERMS_AND_CONDITIONS')">
                                                {{ t("LBL_PREMIUM_TERMS_AND_CONDITIONS") }}
                                          </router-link>
                                          <router-link to="/praemien/praemienaktionen/gutschein-agb" target="_blank" :title="t('LBL_COUPON_TERMS_AND_CONDITIONS')">
                                                {{ t("LBL_COUPON_TERMS_AND_CONDITIONS") }}
                                          </router-link>
                                          <router-link to="/praemien/praemienaktionen/praemien-impressum" target="_blank" :title="t('LBL_IMPRESSUM')">{{ t("LBL_IMPRESSUM") }} </router-link>

                                          <a class="text-blue" href="https://www.payback.at/kontakt" target="_blank" :title="t('LBL_CONTACT')">{{ t("LBL_CONTACT") }} </a>

                                          <a class="text-blue" href="https://www.payback.at/datenschutz/uebersicht" target="_blank" :title="t('LBL_DATA_PROTECTION')">
                                                {{ t("LBL_DATA_PROTECTION") }}
                                          </a>

                                          <a class="text-blue" href="https://www.payback.at/datenschutz/cookies" target="_blank" :title="t('LBL_COOKIE_SETTINGS')">
                                                {{ t("LBL_COOKIE_SETTINGS") }}
                                          </a>

                                          <div class="text-black footer--rewardsshop_disclaimer">
                                                {{ t("LBL_ALL_PRICES_INCL_TAXES") }}
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      </section>
</template>

<script>
      import { useI18n } from "vue-i18n";
      import { useStore } from "vuex";
      import { useRouter } from "vue-router";

      export default {
            name: "FooterDefault",
            props: {
                  msg: String
            },
            components: {},
            setup() {
                  const { t } = useI18n();
                  const store = useStore();
                  const router = useRouter();
                  const isLoggedIn = store.getters["account/isLoggedIn"];

                  const checkLogin = () => {
                        if (isLoggedIn) {
                              router.push({ name: "my-orders" });
                        } else {
                              const data = {
                                    returnUrl: "/praemien/meine-bestellungen",
                                    loginType: "normal"
                              };
                              store.dispatch("auth/redirectToLoginPage", data);
                        }
                  };
                  return {
                        t,
                        checkLogin
                  };
            }
      };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
      div.footer {
            @apply text-blue;
      }

      .page-footer-lightblue,
      .page-footer-white {
            &__container {
                  &-content {
                        &__table {
                              @apply grid grid-cols-4 gap-2 grid-flow-row-dense  text-center;
                              &-content {
                                    @apply flex flex-col justify-center items-center text-blue;
                                    & > div {
                                          @apply rounded-full bg-white shadow-md mb-2;
                                          padding: 18px;
                                          img {
                                                width: 36px;
                                                height: 36px;
                                          }
                                    }

                                    h3 {
                                          @apply text-blue;
                                          font-family: PAYBACKLight, sans-serif;
                                          font-style: normal;
                                          font-weight: normal;
                                          font-size: 20px;
                                          line-height: 22px;
                                    }
                              }
                              @media (min-width: 992px) {
                                    @apply grid-cols-4;
                              }
                              @media (max-width: 600px) {
                                    @apply grid-cols-2;
                              }
                              @media (max-width: 300px) {
                                    @apply grid-cols-1;
                              }
                        }

                        &__table2 {
                              @apply grid grid-cols-3  gap-2 grid-flow-row-dense  text-center;
                              &-content {
                                    @apply flex flex-col justify-start items-start text-left text-blue;
                                    /* &.safe-payment {
            grid-row: 1/-1;
          }
          &.service-help {
            grid-row: 1/2;
          }
          &.free-shipping {
            grid-row: 2/-1;
          } */
                                    .content-wrapper {
                                          @apply pl-4 pt-4 w-full;
                                          a {
                                                display: inline-flex;
                                                .list-icon {
                                                      @apply mr-5;
                                                      height: 20px;
                                                      width: auto;
                                                      max-width: 20px;
                                                }
                                          }
                                          .no-icon {
                                                a {
                                                      margin-left: 2.5rem;
                                                }
                                          }
                                    }
                                    img {
                                          max-width: 200px;
                                    }
                                    ul {
                                          @apply list-none;
                                          list-style-image: none;

                                          li {
                                                @apply px-0 py-4 relative;

                                                a {
                                                      @apply text-blue block;
                                                      text-decoration: none;
                                                      line-height: 21px;
                                                }

                                                &:hover,
                                                &:focus {
                                                      @apply text-blue bg-white underline;
                                                }
                                          }
                                    }

                                    .card-image-wrapper-footer {
                                          @apply justify-center justify-center;
                                          border-radius: 8px;
                                    }

                                    .card-image-footer {
                                          width: 70px;
                                          height: 47px;
                                    }
                              }
                              @media (min-width: 992px) {
                                    @apply grid-cols-3;
                              }
                              @media (max-width: 991px) {
                                    @apply grid-cols-2;
                              }
                              @media (max-width: 767px) {
                                    @apply grid-cols-1;
                                    &-content {
                                          @apply flex flex-col justify-start items-start text-left text-blue;
                                          /* &.safe-payment {
              grid-row: auto;
            }
            &.service-help {
              grid-row: auto;
            }
            &.free-shipping {
              grid-row: auto;
            } */
                                          .content-wrapper {
                                                @apply pt-4 flex flex-col justify-start items-start;
                                                box-sizing: border-box;
                                          }
                                          ul {
                                                @apply list-none;
                                                list-style-image: none;

                                                li {
                                                      @apply pl-0 pr-20 py-4 relative;
                                                      &:after {
                                                            content: "";
                                                            background: url(../../assets/img/icons/chevron_right_24px.svg) no-repeat;
                                                            background-position: center;
                                                            position: absolute;
                                                            right: 0;
                                                            top: 50%;
                                                            height: 24px;
                                                            width: 24px;
                                                            transform: translateY(-50%);
                                                      }
                                                      a {
                                                            @apply text-blue block;
                                                            text-decoration: none;
                                                            line-height: 21px;
                                                      }

                                                      &:hover,
                                                      &:focus {
                                                            @apply text-blue bg-white underline;
                                                      }
                                                }
                                          }
                                    }
                              }
                        }
                  }
                  @media (max-width: 767px) {
                        width: 90%;
                  }
            }
      }

      .footer--rewardsshop_disclaimer {
            justify-self: flex-end;
            margin-left: auto;
            @media (max-width: 767px) {
                  justify-self: center;
                  align-self: flex-start;
                  margin-left: 0;
            }
      }
      .footer-links {
            a {
                  padding: 0.5rem 0;
            }
            @media (max-width: 767px) {
                  flex-direction: column;
            }
      }
      .footer-links-container {
            margin-top: 4rem;
      }
      .spacer {
            @apply text-black m-2;
      }
</style>
