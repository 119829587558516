export function useCartDefaultGetters() {
  return {
    cart(state) {
      return state.cart;
    },

    pointsDiscount(state, getters) {
      const cart = getters["cart"];
      const discounts = cart?.discounts;
      if (Array.isArray(discounts) && discounts.length > 0) {
        const pointPayment = discounts.filter((obj) => {
          return obj.type_id === "POINTS";
        });
        if (Array.isArray(pointPayment) && pointPayment.length > 0) {
          return pointPayment[0].value.toString();
        }
      }
      return 0;
    },
    
    voucherDiscount(state, getters) {
      const cart = getters["cart"];
      const discounts = cart?.discounts;
      if (Array.isArray(discounts) && discounts.length > 0) {
        const voucherPayment = discounts.filter((obj) => {
          return obj.type_id === "VOUCHER";
        });
        if (Array.isArray(voucherPayment) && voucherPayment.length > 0) {
          return voucherPayment[0].value;
        }
      }
      return 0;
    },

    voucherActionType(state, getters) {
      const cart = getters["cart"];
      const discounts = cart?.discounts;
      if (Array.isArray(discounts) && discounts.length > 0) {
        const voucherPayment = discounts.filter((obj) => {
          return obj.type_id === "VOUCHER";
        });
        if (Array.isArray(voucherPayment) && voucherPayment.length > 0) {
          return voucherPayment[0].action;
        }
      }
      return 0;
    },

    totalSumWithVoucherDiscount(state, getters) {
      const cart = getters["cart"];
      return cart?.total_sum_with_voucher_discount;
    },

    grandTotalWithVoucherDiscount(state, getters) {
      const cart = getters["cart"];
      return cart?.grand_total_with_discounts;
    },

    getItems(state, getters) {
      const cart = getters["cart"];
      return cart?.items;
    },
    minPoints(state, getters) {
      const cart = getters["cart"];
      return cart?.minPoints;
    },
    additionalPayment(state, getters) {
      const cart = getters["cart"];
      const discount = parseInt(getters["pointsDiscount"]) + parseInt(getters["voucherDiscount"]);
      if (discount > 0) {
        return Math.abs(discount - cart?.total_sum_with_voucher_discount);
      }
      else {
        return 0
      }
    },
  };
}
