import api from '@/backend/api'

export default {
    async getMainPageContents() {
        return await api.get('/cms/main-page-content');
    },

    async getRightSideLinks() {
        return await api.get('/cms/right-side-links');
    },
}
