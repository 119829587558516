<template>
      <div class="container my-0 mx-auto category-footer">
            <div class="grid lg:grid-flow-row md:grid-flow-row lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 xxs:grid-cols-2">
                  <div v-for="image in images" :key="image" class="teaser bg-blue-lighter lg:m-3 xs:m-1 xxs:m-1 lg:p-2 xs:p-1 xxs:p-1">
                        <router-link :to="`/praemien/kategorie/${categoryUrls[image.name]}`" :title="image.name" @click="redirectToCategoryPage(image.name)">
                              <image-gallery :image="image.path" :alt="image.name"></image-gallery>
                              <h6 class="text-center centered middled">{{ image.name }}</h6>
                        </router-link>
                  </div>
            </div>
      </div>
</template>

<script>
      import ImageGallery from "@/components/Image/ImageGallery";
      import { reactive, onMounted, ref, computed } from "vue";
      import constants from "@/helpers/constants";
      import { useRouter } from "vue-router";
      import { useStore } from "vuex";
      import EventBus from "@/helpers/event-bus";
      export default {
            name: "CategoriesFooter",
            setup() {
                  const images = reactive([]);
                  const router = useRouter();
                  const store = useStore();
                  const categoryUrls = ref(constants.CATEGORY_URLS);

                  onMounted(() => {
                        importImages();
                  });

                  const importImages = async () => {
                        const imageContext = require.context("@/assets/footer-categories", false, /\.(png|jpe?g|svg)$/);

                        const imagePaths = imageContext.keys();

                        for (const imagePath of imagePaths) {
                              const path = await getImagePath(imagePath);
                              const name = getImageName(imagePath);

                              const image = {
                                    path: path,
                                    name: name
                              };

                              images.push(image);
                        }
                  };

                  const getImagePath = async (imagePath) => {
                        const concatPath = imagePath.replace("./", "");
                        const image = await import(`@/assets/footer-categories/${concatPath}`);
                        return image.default;
                  };

                  const getImageName = (imagePath) => {
                        const imageNameWithExtension = imagePath.split("/").pop();
                        const imageNameWithoutExtension = imageNameWithExtension.split(".")[0];
                        return imageNameWithoutExtension;
                  };

                  const allCategories = computed(() => {
                        return store.getters["catalog/getCategories"];
                  });

                  const redirectToCategoryPage = (categoryName) => {
                        store.dispatch("catalog/setFooterCategoryClick", true);

                        const filteredCategories = allCategories?.value.children?.find((category) => category.name === categoryName);
                        EventBus.emit("footerCategoryClicked", { category: filteredCategories, type: "parentCategory", toggleCall: false });
                  };

                  return {
                        images,
                        categoryUrls,
                        redirectToCategoryPage
                  };
            },
            components: {
                  ImageGallery
            }
      };
</script>
<style>
      .middled {
            position: absolute;
            transform: translate(-50%, -50%);
      }
      .category-footer img {
            width: 100%;
            height: 100%;
      }
      @media screen and (max-width: 767px) {
            .middled {
                  font-size: 11px;
            }
      }
      .category-footer .teaser {
            position: relative;
            box-shadow: inset 0 0 0 5px #fff;
            transition: box-shadow 0.3s ease-in-out;
            cursor: pointer;
      }
      .teaser:hover {
            box-shadow: inset 0 0 0 5px #bfcbdd;
      }
      .middled {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow-wrap: anywhere;
            font-family: "Open Sans", Arial, sans-serif;
      }
</style>
