import { parseTrackingValue } from "@/service/parseTrackingValue";
import { cookieService } from "@/service/cookieConsentService";
import { isObject } from "lodash-es";
import store from "@/store/store";
import router from "@/router";

export default class tService {
  constructor() {
    this.initTime = 0;
    this.currentState = "";
    this.orderSuccessData = {};
    this.persistentData = {};
    this.parseTrackingValue = parseTrackingValue;
    this.isLoggedIn = store.getters["account/isLoggedIn"];
  }


  init = (cart) => {
    const currentRoute = router.currentRoute;
    window.digitalData_rewardsshop = {
      user: {
        profile: {
          profileInfo: {
            loginStatus: this.isLoggedIn ? 'auth' : 'guest'
          }
        }
      }, site: {
        siteInfo: {
          environment: process.env.NODE_ENV_TR,
          country: "AT",
          language: "de",
          namespace: "rewardsshop",
          version: "1.00",
          currency: "EUR",
        },
      },
      page: {
        pageInfo: {
          pageName: currentRoute.value.meta.trackingName,
          viewport: this.isMobile() ? "mobile_view" : "desktop_view",
          pageURL: window.location.href,
          pageType: currentRoute.value.name,
          siteSection: 'rewards',
          errorID: undefined,
          errorName: undefined
        },
        attributes: {
          deploymentChannel: "epay",
          country: 'at',
          language: 'de',
          server: window.location.origin
        },
        category: {
          primaryCategory: "rewards",
          subCategory1: currentRoute?.value?.meta?.trackingSubCategory1 ? currentRoute.value.meta.trackingSubCategory1 : '',
          subCategory2: currentRoute?.value?.meta?.trackingSubCategory2 ? currentRoute.value.meta.trackingSubCategory2 : '',
        },
      },
    };
    if (isObject(cart)) {
      this.cart = cart;
    }
    this.appendPersistentData();
  };
  isMobile() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }
  updateCart(cart) {
    if (isObject(cart)) {
      this.cart = cart;
    }
  }

  appendPersistentData = () => {
    window.digitalData_rewardsshop = this._mergeObjects(window.digitalData_rewardsshop, tService.persistentData);
  };

  setPersistentData(value) {
    this.persistentData = this._mergeObjects(this.persistentData, value);

    this.appendPersistentData();
  }

  setPageName(name) {
    window.digitalData_rewardsshop.page.pageInfo.pageName = name;
  }

  setCategory(category) {
    if (!window.digitalData_rewardsshop?.page?.category) {
      window.digitalData_rewardsshop.page.category = [];
    }

    window.digitalData_rewardsshop.page.category.primaryCategory = category.primaryCategory;
    window.digitalData_rewardsshop.page.category.subCategory1 = category.subCategory;

    //console.info('tracking() category was updated');
  }

  setPageInfo(pageInfo, replace) {
    if (replace === true || !isObject(window.digitalData_rewardsshop.page.pageInfo)) {
      window.digitalData_rewardsshop.page.pageInfo = pageInfo;
    } else {
      Object.assign(window.digitalData_rewardsshop.page.pageInfo, pageInfo);
    }
  }

  setProducts(products) {
    window.digitalData_rewardsshop.product = products;
  }

  setTransactionInfo(transactionInfo) {
    window.digitalData_rewardsshop.transaction = transactionInfo;
  }

  _mergeObjects(one, two) {
    let extended = {};
    Object.assign(extended, one, two);
    return extended;
  }

  track(loggerType, initTime) {
    if (cookieService.isStatisticsGroupEnabled()) {
      if (isObject(this.cart)) {
        window.digitalData_rewardsshop.cart = this.cart;
      }
      try {
        if (_satellite) {
          _satellite.track("rewardsshop_pageload");
        }
      } catch (e) {
        console.log(e);
      }

      let currentTime = Date.now();
      let timeData = {
        time: currentTime - initTime,
        currentState: loggerType,
      };
      store.dispatch("cart/logger", timeData);
    }
  }
}
