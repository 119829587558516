export default {
    products: [],
    pagination: { paginationCurrentPage: 0, paginationPageSize: 64 },
    filters: {},
    product: {},
    category: {},
    categories: [],
    landingPage: {},
    categoryName: '',
    productsLoaded: false,
    footerCategoryClicked: false
}
