import { useCartDefaultMutations } from '@/composables/useCartDefaultMutations'

export default {
  ...useCartDefaultMutations(),
  setLogger(state, logger) {
    state.logger = logger;
  },
  setStepper(state, step) {
    state.step = step;
  },
  setCartArrayIndex(state, cartArrayIndex) {
    state.cartArrayIndex = cartArrayIndex
  }
}