<template>
  <div class="mt-5">
<!--    should add sync for two-way binding-->
    <slider ref="slider$" v-model="input.value" v-bind="input" @update="$emit('sliderOutPut', $event)" @change="$emit('sliderOutPutFetch', $event)" class="slider" sync></slider>
  </div>
</template>

<script>
  import Slider from "@vueform/slider";
  import {computed, ref} from "vue";

  export default {
    name: "InputSlider",
    props: ["min", "max", "defaultRange"],
    emits: ["sliderOutPut", "sliderOutPutFetch"],
    components: {
      Slider,
    },
    setup(props) {
      const slider$ = ref(null)

      const setDate = (value) => {
        slider$.value.update(value)
      }
      const getCurData = computed(() => {
        return JSON.stringify(slider$.value.input)
      })
      return {
        input: {
          value: props.defaultRange,
          tooltips: false,
          lazy:false,
          min: props.min,
          max: props.max,
          // format: function (value) {
          //   return `${Math.ceil(value / 10) * 10}`
          // }
        },
        setDate,
        getCurData,
        slider$
      };
    },

  };
</script>

<style src="../../../node_modules/@vueform/slider/themes/default.css"></style>
<style lang="postcss" scoped>
  .slider {
    --slider-connect-bg: #7fa2d4;
    --slider-tooltip-bg: #3b82f6;
    --slider-handle-ring-color: #3b82f630;
    --slider-height: 15px;
    --slider-radius: 9999px;

    --slider-handle-bg: linear-gradient(to bottom, #f5faff 0, #fff 100%);
    --slider-handle-border: 0;
    --slider-handle-width: 1.9rem;
    --slider-handle-height: 1.9rem;
    --slider-handle-radius: 9999px;
    --slider-handle-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.42);
    --slider-handle-ring-width: 3px;
    --slider-handle-ring-color: #10b98130;
  }
</style>
