<template>
      <img :src="image" :alt="alt" />
</template>

<script>
      export default {
            name: "ImageGallery",
            props: {
                  image: String,
                  alt:String
            }
      };
</script>
