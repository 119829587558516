<template>
      <div v-if="showShiftyBar">
            <div v-for="image in cms" :key="image.id">
                  <!--    Desktop-->
                  <div
                        v-if="image.tag === 'SHIFTYBAR_IMAGE'"
                        class="hidden sm:flex shifty-bar"
                        :style="{
                              height: shiftyBar?.height + 'px',
                              background: image.shiftybarBackgroundColorValue ? image.shiftybarBackgroundColorValue : '#fdc600',
                              color: shiftyBar?.color,
                              'font-size': shiftyBar?.fontSize + 'px'
                        }"
                  >
                        <div class="shifty-bar__container container py-2 sm:pr-3">
                              <div class="shifty-bar__wrapper flex-col w-full items-center justify-center">
                                    <div class="w-1/4 items-center justify-center">
                                          <img class="w-auto px-2 inline-block shifty-bar-image" :src="image?.imageName" :alt="image?.tag" />
                                    </div>
                                    <div class="w-3/4 inline-flex items-center">
                                          <p class="text-sm w-3/4" :style="{color: image.shiftybarTextColorValue}">
                                                {{ image?.text ?? " Hallo das ist ein Test! Hallo das ist ein Test! Hallo das ist ein Test!" }}
                                          </p>
                                          <a v-if="image?.url"
                                                class="shifty-bar__button w-1/4 justify-center"
                                                :href="image?.url"
                                                :style="{
                                                      width: button?.width + 'px',
                                                      height: button?.height + 'px',
                                                      background: button?.backGround,
                                                      color: button?.color,
                                                      'font-size': button?.fontSize + 'px'
                                                }"
                                                >{{ image.shiftybarRightButtonTextValue ? image.shiftybarRightButtonTextValue : "Klick hier" }}</a
                                          >
                                    </div>
                              </div>

                              <div class="closeshifty-bar sm:pr-3" @click="closeShiftyBar">x</div>
                        </div>
                  </div>

                  <!--    Mobile-->
                  <div
                        v-if="image.tag === 'SHIFTYBAR_MOBILE_IMAGE'"
                        class="flex sm:hidden shifty-bar"
                        :style="{
                              height: shiftyBar?.height + 'px',
                              background: image.shiftybarBackgroundColorValue ? image.shiftybarBackgroundColorValue : '#fdc600',
                              color: shiftyBar?.color,
                              'font-size': shiftyBar?.fontSize + 'px'
                        }"
                  >
                        <div class="shifty-bar__container container">
                              <div class="shifty-bar__wrapper w-full flex-col items-end justify-end">
                                    <div class="w-1/3 items-end justify-end"><img class="w-auto px-2 inline-block" :src="image?.imageName" :alt="image?.tag" /></div>
                                    <div class="w-2/3">
                                          <p class="text-sm" :style="{color: image.shiftybarTextColorValue}">
                                                {{ image?.text ?? " Hallo das ist ein Test! Hallo das ist ein Test! Hallo das ist ein Test!" }}
                                          </p>
                                          <a v-if="image?.url"
                                                class="shifty-bar__button"
                                                :href="image?.url"
                                                :style="{
                                                      width: button?.width + 'px',
                                                      height: button?.height + 'px',
                                                      background: button?.backGround,
                                                      color: button?.color,
                                                      'font-size': button?.fontSize + 'px'
                                                }"
                                                >{{ image.shiftybarRightButtonTextValue ? image.shiftybarRightButtonTextValue : "Klick hier" }}</a
                                          >
                                    </div>
                              </div>

                              <div class="closeshifty-bar" @click="closeShiftyBar">x</div>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
      import { useStore } from "vuex";
      import { computed, onMounted, ref } from "vue";

      export default {
            name: "ShiftyBar",
            props: {
                  shiftyBar: {
                        backGround: {
                              type: String,
                              default: "#fdc600"
                        },
                        fontSize: {
                              type: String,
                              default: ""
                        },
                        color: {
                              type: String,
                              default: ""
                        },
                        height: {
                              type: String,
                              default: ""
                        },
                        text: {
                              type: String,
                              default: ""
                        },
                        imageSrc: {
                              type: String,
                              default: ""
                        }
                  },
                  button: {
                        backGround: {
                              type: String,
                              default: ""
                        },
                        fontSize: {
                              type: String,
                              default: ""
                        },
                        width: {
                              type: String,
                              default: ""
                        },
                        height: {
                              type: String,
                              default: ""
                        },
                        color: {
                              type: String,
                              default: ""
                        },
                        text: {
                              type: String,
                              default: "Jetzt sichern"
                        },
                        link: {
                              type: String,
                              default: "https://praemien.payback.at/praemien/praemienaktionen/punktegeschenk"
                        }
                  }
            },
            components: {},
            setup() {
                  const store = useStore();
                  const showShiftyBar = ref(false);

                  const cms = computed(() => {
                        let cms = store.getters["cms/getMainPageContents"];
                        cms = Object.values(cms);
                        if (cms && cms.length > 0) {
                              return cms.filter(function (data) {
                                    return ["SHIFTYBAR_IMAGE", "SHIFTYBAR_MOBILE_IMAGE"].includes(data.tag);
                              });
                        }
                        return [];
                  });
                  const closeShiftyBar = () => {
                        showShiftyBar.value = false;
                  };

                  onMounted(() => {
                        if (cms.value && cms?.value[0]?.text !== "") {
                              showShiftyBar.value = true;
                        }
                  });
                  return {
                        cms,
                        showShiftyBar,
                        closeShiftyBar
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .shifty-bar {
            position: relative;
            height: 100px;
            width: 100%;
            justify-content: center;
            align-items: center;
      }
      .shifty-bar__shifty-bar {
            font-weight: bold;
            font-family: "PAYBACKLight", sans-serif;
            font-size: 30px;
            margin-right: 30px;
      }

      .shifty-bar__button {
            border-radius: 0.2rem;
            background: #c1002b;
            display: flex;
            height: 40px;
            color: #fff;
            white-space: nowrap;
            font-weight: bold;
            padding: 8px 20px;
            justify-content: center;
            align-items: center;
            width: 160px;
            margin-left: 20px;
      }
      .shifty-bar__container {
            position: relative;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
      }
      .shifty-bar__wrapper {
            position: relative;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
      }
      .closeshifty-bar {
            color: #0046aa;
            font-weight: bold;
            font-size: 1.2rem;
            position: relative;
            right: 0;
            top: -40px;
            cursor: pointer;
      }
      .shifty-bar-image {
            height: 100px;
      }
      @media screen and (max-width: 767px) {
            .shifty-bar {
                  padding: 20px 30px 0 10px;
                  height: auto;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  background: #fdc600;
            }
            .shifty-bar .container {
                  position: relative;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
            }
            .shifty-bar__shifty-bar {
                  font-weight: bold;
                  font-family: "PAYBACKLight", sans-serif;
                  font-size: 24px;
            }

            .shifty-bar__button {
                  border-radius: 0.2rem;
                  background: #c1002b;
                  display: flex;
                  height: 30px;
                  color: #fff;
                  white-space: nowrap;
                  font-weight: bold;
                  padding: 8px 20px;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  margin-left: 0;
                  margin: 20px 0;
            }
      }

      @media screen and (max-width: 767px) {
            .shifty-bar {
                  padding: 20px 30px 0 10px;
                  height: auto;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  background: #fdc600;
            }
            .shifty-bar .container {
                  position: relative;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
            }
            .shifty-bar__shifty-bar {
                  font-weight: bold;
                  font-family: "PAYBACKLight", sans-serif;
                  font-size: 24px;
            }

            .shifty-bar__button {
                  border-radius: 0.2rem;
                  background: #c1002b;
                  display: flex;
                  height: 30px;
                  color: #fff;
                  white-space: nowrap;
                  font-weight: bold;
                  padding: 8px 20px;
                  justify-content: center;
                  align-items: center;
                  margin-left: 0;
                  margin: 20px 0;
            }
            .closeshifty-bar {
                  color: #0046aa;
                  font-weight: bold;
                  font-size: 1.2rem;
                  position: absolute;
                  right: 0;
                  top: -20px;
                  cursor: pointer;
            }
      }

      /* END 10% BANNER */
</style>
