<template>
  <div id="app-inner">
    <default-layout v-if="isStarting"></default-layout>
    <notification-bar></notification-bar>
    <div id="payBackTrustbadge" class="flex justify-center bg-light"></div>
  </div>
</template>

<script>
  import DefaultLayout from "@/layouts/DefaultLayout";
  import NotificationBar from "@/components/NotificationBar/NotificationBar";
  import { useStore } from "vuex";
  import { computed, onMounted } from "vue";
  import "@/assets/styles/index.css";

  export default {
    name: "App",
    components: {
      DefaultLayout,
      NotificationBar,
    },

    setup() {
      const store = useStore();
      store.dispatch("auth/executeStart");
      const initTrustedBadger = () => {
        _tsConfig = {
          yOffset: "0" /* offset from page bottom */,
          variant: "reviews" /* default, reviews, custom, custom_reviews */,
          customElementId: "" /* required for variants custom and custom_reviews */,
          trustcardDirection: "" /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
          customBadgeWidth: "" /* for custom variants: 40 - 90 (in pixels) */,
          customBadgeHeight: "" /* for custom variants: 40 - 90 (in pixels) */,
          disableResponsive: "false" /* deactivate responsive behaviour */,
          disableTrustbadge: "false" /* deactivate trustbadge */,
        };
        let _tsid = "X1B804ABF1400BAB98DCD909EAAD13EC5";
        let _ts = document.createElement("script");
        _ts.type = "text/javascript";
        _ts.charset = "utf-8";
        _ts.async = true;
        _ts.src = "//widgets.trustedshops.com/js/" + _tsid + ".js";
        let __ts = document.getElementsByTagName("script")[0];
        __ts.parentNode.insertBefore(_ts, __ts);
      };
      onMounted(async () => {
        initTrustedBadger();
      });

      // @todo translation is not implemented yet
      // localisation
      // const { availableLocales, t } = useI18n({ useScope: 'global' })
      // const setLocale = async (locale) => {
      //   if (supportedLocales.includes(locale)) {
      //     if (!availableLocales.includes(locale)) {
      //       await loadLocale(locale)
      //     }
      //   }
      // }

      return {
        isStarting: computed(() => store.getters["auth/isStarting"]),
      };
    },
  };
</script>

<style lang="postcss">
  #app {
    overflow-x: hidden;
  }
  @font-face {
    font-family: "PAYBACKBold";
    src: url("./assets/fonts/payback/bold/payback_bold-webfont.eot");
    src: url("./assets/fonts/payback/bold/payback_bold-webfont.eot") format("embedded-opentype"), url("./assets/fonts/payback/bold/payback_bold-webfont.woff") format("woff"),
      url("./assets/fonts/payback/bold/payback_bold-webfont.ttf") format("truetype"), url("./assets/fonts/payback/bold/payback_bold-webfont.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "PAYBACKScriptRegular";
    src: url("./assets/fonts/payback/script/payback_script.eot");
    src: url("./assets/fonts/payback/script/payback_script.eot") format("embedded-opentype"), url("./assets/fonts/payback/script/payback_script.woff") format("woff"),
      url("./assets/fonts/payback/script/payback_script.ttf") format("truetype"), url("./assets/fonts/payback/script/payback_script.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "PAYBACKLight";
    src: url("./assets/fonts/payback/light/payback_light-webfont.eot");
    src: url("./assets/fonts/payback/light/payback_light-webfont.eot") format("embedded-opentype"), url("./assets/fonts/payback/light/payback_light-webfont.woff") format("woff"),
      url("./assets/fonts/payback/light/payback_light-webfont.ttf") format("truetype"), url("./assets/fonts/payback/light/payback_light-webfont.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "PAYBACKRegular";
    src: url("./assets/fonts/payback/regular/payback_regular-webfont.eot");
    src: url("./assets/fonts/payback/regular/payback_regular-webfont.eot") format("embedded-opentype"), url("./assets/fonts/payback/regular/payback_regular-webfont.woff") format("woff"),
      url("./assets/fonts/payback/regular/payback_regular-webfont.ttf") format("truetype"), url("./assets/fonts/payback/regular/payback_regular-webfont.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-Bold.eot");
    src: url("./assets/fonts/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-Bold.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-Bold.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-Light.eot");
    src: url("./assets/fonts/opensans/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-Light.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-Light.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-SemiBoldItalic.eot");
    src: url("./assets/fonts/opensans/OpenSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-SemiBoldItalic.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-SemiBoldItalic.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-MediumItalic.eot");
    src: url("./assets/fonts/opensans/OpenSans-MediumItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-MediumItalic.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-MediumItalic.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-Medium.eot");
    src: url("./assets/fonts/opensans/OpenSans-Medium.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-Medium.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-Medium.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-ExtraBold.eot");
    src: url("./assets/fonts/opensans/OpenSans-ExtraBold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-ExtraBold.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-ExtraBold.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-SemiBold.eot");
    src: url("./assets/fonts/opensans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-SemiBold.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-ExtraBoldItalic.eot");
    src: url("./assets/fonts/opensans/OpenSans-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-ExtraBoldItalic.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-LightItalic.eot");
    src: url("./assets/fonts/opensans/OpenSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-LightItalic.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-LightItalic.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-Italic.eot");
    src: url("./assets/fonts/opensans/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-Italic.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-Italic.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-Regular.eot");
    src: url("./assets/fonts/opensans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-Regular.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-Regular.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/opensans/OpenSans-BoldItalic.eot");
    src: url("./assets/fonts/opensans/OpenSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/opensans/OpenSans-BoldItalic.woff2") format("woff2"),
      url("./assets/fonts/opensans/OpenSans-BoldItalic.woff") format("woff"), url("./assets/fonts/opensans/OpenSans-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  h1 {
    @apply mb-4 type-h1 text-gray-dark leading-12 font-paybackHeading font-normal;
  }

  h2 {
    @apply type-h2 text-gray-dark leading-10 font-paybackHeading font-normal;
  }

  h3 {
    @apply text-black-light type-h3 leading-10 font-paybackHeading font-normal;
  }

  p,
  strong {
    @apply font-paybackParagraph;
    /*color: #313133;*/
  }

  p > strong {
    @apply text-sm leading-5 font-bold  mb-2;
  }
  /* .product-description {
  p > strong {
    @apply block;
    margin: 1.75rem 0rem 1rem 0rem;
  }
} */

  * {
    box-sizing: border-box;
    image-rendering: -webkit-optimize-contrast;
  }
  ul {
    @apply leading-6;
    list-style-image: url("./assets/img/bullet.svg");
  }
  ul[style*="list-style-type: disc;"] {
    list-style-image: none;
    margin-left: 1rem;
    li {
      background-image: none;
    }
  }
  .product {
    .slide_image {
      @media screen and (max-width: 850px) {
        &-image {
          zoom: 0.8;
          max-width: 85%;
          margin: auto;
          height: auto;
        }
      }
      @media screen and (max-width: 767px) {
        height: 160px;
        &-image {
          max-width: 85%;
          margin: auto;
          height: auto;
          zoom: 0.8;
        }
      }
    }
  }
</style>
