import accountApi from "../../backend/services/account";

export default {
  async getAccount({ commit, getters }) {
    let data = getters['account']

    if (!data || !data.addresses || data.addresses.length === 0) {
      data = await accountApi.get();
    }

    commit("setAccount", data);
    return data;
  },

  async validateAddress({ commit }, address) {
    return await accountApi.validateAddress(address);
  },
  async getAddressPois({ commit }, address) {
    return await accountApi.getAddressPois(address);
  },

  async setLoggedIn({ commit }, pingData) {
    if (pingData && pingData.login_token_valid && (pingData.login_token_valid === true || pingData.login_token_valid == "true")) {
      commit("setIsLoggedIn", true);
    }
    else {
      commit("setIsLoggedIn", false);
    }
    return pingData;
  }
}