export default {
    setStart(state, start) {
        state.start = start;
    },
    setPing(state, ping) {
        state.ping = ping;
    },
    isStarting(state, isStarting) {
        state.isStarting = isStarting;
    },
    
}