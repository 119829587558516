export default {
    get(state) {
        return state.data;
    },
    paymentType(state, getters) {
      const response = getters["get"];
      if ("cardtype" in response) {
        return response["cardtype"].toLowerCase();
      }
      return "";
    }
}
