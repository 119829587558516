export default {
    getStart(state) {
        return state.start;
    },
    ping(state) {
        return state.ping;
    },
    customerPoints(stage, getters){
        const ping = getters['ping']
        if(ping.customer && ping.customer.points){
            return ping.customer.points
        }
        return 0
    },
    isStarting(state) {
        return state.isStarting;
    }
}
