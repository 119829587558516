import storage from "@/helpers/storage";

export default function amountFormatter(amount, currency) {
    const lang = storage.get('language') || 'de';
    amount = amount/100;
    let options = {};
    if(currency) {
        options = {
            style: 'currency',
            currency: currency.toUpperCase()
        };
    }
    let formatter = new Intl.NumberFormat(lang, options);
    return formatter ? formatter.format(amount) : 0;
}