<template>
      <div class="notification-box" v-if="notification && notification.text && notification.code !== 'NO_MIXED'">
            <div :class="notification.type" class="notification divide-solid divide-white">
                  <div class="notification__close" @click="closeNotification">x</div>
                  <h2 class="font-bold text-xl pb-2 text-white">
                        {{ checkNotificationType(notification.type) }}
                  </h2>
                  <p class="pt-2">{{ notification.text }}</p>
            </div>
      </div>

<!--  Notification when generate mixed shopping cart -->
  <div class="notification-box-alert shadow-xl border-1 m-auto bg-white w-full sm:w-5/6 md:w-4/6" v-if="notification && notification.code === 'NO_MIXED' ">
    <div :class="notification.type" class="notification space-y-1 divide-solid divide-white ">
      <h1 class="font-bold font-paybackHeading text-2xl md:text-3xl pb-2 text-blue text-center">
        {{ checkNotificationType(notification.type) }}
      </h1>
      <div class="flex flex-wrap">
        <div class="flex-initial w-full sm:w-1/3 md:w-5/12 h-auto">
          <img class="" src="@/assets/img/error-no-mixed.png" alt="">
        </div>
        <p class="flex-initial w-full sm:w-2/3 md:w-7/12 p-5 md:p-5 text-black font-paybackParagraph " v-html="notification.text"></p>
      </div>
      <div class="w-2/3 m-auto pt-2 md:pt-5">
        <Button :css-class="'green'" :text="'Alles klar'" @click="closeNotification"></Button>
      </div>
    </div>
  </div>
</template>

<script>
      import { computed, watch } from "vue";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";
      import Button from "@/components/Button/Button";
      export default {
            name: "NotificationBar",
        components: {Button},
            setup() {
                  const store = useStore();
                  const { t } = useI18n();

                  const checkNotificationType = (type) => {
                        if (type) {
                              switch (type) {
                                    case "info":
                                          return t("LBL_INFO");
                                    case "success":
                                          return t("LBL_SUCCESS");
                                    case "error":
                                          return t("LBL_ERROR");
                                    case "alert_no_mix":
                                      return t("LBL_NO_MIX");
                              }
                        }
                  };
                  const notification = computed(() => {
                        const notification = store.getters["notification/get"];
                        if (notification) {
                              switch (notification.code) {
                                    case "ITEM_LIMITED_AMOUNT":
                                          notification.text = "Dieser Artikel kann leider nur in begrenzter Stückzahl bestellt werden";
                                          break;
                                    case "COORDER_CHANGED":
                                          notification.text = "Hinweis: Der Preis Ihrer Mitbestell-Prämie hat sich geändert.";
                                          break;
                                    case "COORDER_ADDED":
                                          notification.text = "Glückwunsch! Die Mitbestellprämie wurde Ihrem Warenkorb zum rabattierten Preis hinzugefügt.";
                                          break;
                                    case "ORDER_RESPONSE":
                                          notification.text = "Beim Absenden der Bestellung ist ein Fehler aufgetreten.";
                                          break;
                                    case "VALIDATION_ERROR":
                                      notification.text = "Bitte wenden sie sich an den Kundensupport.";
                                      break;
                                    case "SERVER_ERROR":
                                          notification.text = "Das System ist auf ein unerwartetes Problem gestoßen.";
                                          break;
                                    case "MSG_MAP_ERROR":
                                          notification.text = "Bitte geben Sie eine gültige Adresse an.";
                                          break;
                                    case "NO_MIXED":
                                          notification.text = "Sie holen sich mit Ihren Punkten gerne Gutscheine und Prämien? Sehr gut, aber ab jetzt bitte dafür immer <b>zwei getrennte Bestellungen aufgeben.</b>";
                                          break;
                              }
                        }
                        return notification;
                  });
                  const closeNotification = function () {
                        if (notification.value !== null) {
                              store.dispatch("notification/set", null);
                        }
                  };
                  watch(
                        () => notification.value,
                        (first, second) => {
                              // console.log("Watch props.selected function called with args:", first, second);
                              return notification;
                        }
                  );

                  watch(
                        () => notification.value,
                        (current, prev) => {
                              if (current !== null && current.code !== 'NO_MIXED') {
                                    setTimeout(function () {
                                          store.dispatch("notification/set", null);
                                    }, 5000);
                              }
                        }
                  );

                  return {
                        notification,
                        t,
                        checkNotificationType,
                        closeNotification,
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .error {
            @apply text-red-dark p-4;
      }
      .info {
            @apply text-green-dark p-4;
      }

      .notification-box {
            position: absolute;
            top: 10%;
            left: calc(100% - 75%);
            z-index: 999;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            .notification {
                  @apply border rounded-md m-2 p-4 relative;
                  width: 50vw;
                  &__close {
                        @apply absolute cursor-pointer text-xl font-normal;
                        right: 15px;
                        top: 15px;
                        &:hover {
                              filter: brightness(0.75);
                        }
                  }
            }


            .success {
                  @apply border-green-dark bg-green-dark text-white;
            }

            .error {
                  @apply border-red-light bg-red-light text-white;
            }

            .info {
                  @apply border-blue bg-blue text-white;
            }

            .warning {
                  @apply border-yellow-warning bg-yellow-warning text-dark;
            }
      }
      @media (max-width: 767px) {
        .notification-box {
          max-width: 100%;
          width: 100vw;
          left: 0;
        }
      }

      html:not([data-scroll='0']) .notification-box-alert {
        position: fixed;
      }
      .notification-box-alert {
        width: 100vw;
        height: 100vh;

        background: rgba(3, 22, 103, 0.87);
        z-index: 999;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
          .notification {
            @apply border bg-white rounded-md m-2 p-4 relative;
            width: 70vw;
            padding: 2%;
            margin-right: auto;
            margin-top: calc((100vh - 600px)/2);
            margin-left: calc((100vw - 70vw)/2);
          &__close {
             @apply absolute cursor-pointer text-xl font-normal;
             right: 15px;
             top: 15px;
          &:hover {
             filter: brightness(0.75);
           }
          }
          }

      .success {
        @apply border-green-dark bg-green-dark text-white;
      }

      .error {
        @apply border-red-light bg-red-light text-white;
      }

      .info {
        @apply border-blue bg-blue text-white;
      }

      .warning {
        @apply border-yellow-warning bg-yellow-warning text-dark;
      }
      }
      @media (min-width: 1024px) {
        .notification-box-alert .notification {
          @apply border bg-white rounded-md m-2 p-4 relative;
          width: 50vw;
          padding: 2%;
          margin-right: auto;
          margin-top: calc((100vh - 700px) / 2);
          margin-left: calc((100vw - 50vw) / 2);
        }
      }
      @media (max-width: 767px) {
        .notification-box-alert {
          max-width: 100%;
          width: 100vw;

        }
      }


</style>
