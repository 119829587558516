import paymentApi from "../../backend/services/payment";

export default {
  async getPaymentUrl({ commit }, params) {
    const data = await paymentApi.getPaymentUrl(params);
    commit("setPaymentUrl", data);
    return data;
  },
  setPaymentUrl({ commit }, value) {
    commit("setPaymentUrl", value);
  },

  // Bargeled Success Payment URL
  async getSuccessPaymentUrl({ commit }, params) {
    const data = await paymentApi.getSuccessPaymentUrl(params);
    commit("setSuccessPaymentUrl", data);
    return data;
  },
  setSuccessPaymentUrl({ commit }, value) {
    commit("setSuccessPaymentUrl", value);
  },
};
