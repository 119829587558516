import api from '@/backend/api'

export default {
    get() {
        return api.get("/account")
    },
    update(item) {
        return api.update("/account", item)
    },
    validateAddress(address) {
        return api.post("/validate-address", address)
    },
    getAddressPois(address) {
        return api.post('/get-address-pois', address)
    }
}
