<template>
      <header class="pb-fixed-nav">
            <iframe width="100%" scrolling="no" id="pb-iframe0" name="pb-iframe-header" :style="{ height: height + 'px' }" :src="iframeSrc" style="width: 1px; min-width: 100%; height: 40px; min-height: 40px; max-height: 100%; border: 0" title="header payback"></iframe>
      </header>
</template>

<script>
      import { onMounted, ref } from "vue";
      export default {
            name: "Header",
            props: {
                  msg: String
            },
            data() {
                  return {
                        height: 40
                  };
            },

            setup() {
                  const iframeSrc = ref(null);

                  onMounted(() => {
                        let domain = window.location.protocol + "//" + window.location.hostname;
                        if (window.location.port > 0) {
                              domain = domain + ":" + window.location.port;
                        }
                        let src = process.env.VUE_APP_HEADER_FRAME;
                        let i = -1 === src.indexOf("?") ? "?" : "&";
                        let generatedSrc = src + i + "frameId=" + "pb-iframe0" + "&parentUrl=" + domain;
                        iframeSrc.value = generatedSrc;
                  });

                  return {
                        iframeSrc
                  };
            },

            components: {}
      };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss">
      .pb-fixed-nav {
            @apply w-full relative top-0 left-0 flex items-center justify-between font-paybackHeading;
            max-width: 100vw;
            padding: 0rem 0rem;
            z-index: 100;
            grid-area: header;
            border-top: 0px solid #ffffff;
            border-right: 0 solid #ffffff;
            border-bottom: 0px solid #ffffff;
            border-left: 0 solid #ffffff;
            -webkit-box-shadow: 1px 1px 1px transparent;
            box-shadow: 1px 1px 1px transparent;
      }
</style>
