import axios from "axios";
import storage from "@/helpers/storage";
import constants from "@/helpers/constants";
import EventBus from "@/helpers/event-bus";
import store from "@/store/store";
import router from "../router/index.js";
import { cookieService } from "@/service/cookieConsentService";
import tErrorInformationService from "@/service/tErrorInformationService.js";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((request) => {
  if (request.url !== "/api/auth/ping") {
    let loopXsrf = setInterval(() => {
      const XSRFTOKEN = document.cookie.indexOf("XSRF-TOKEN") != -1;

      if (!XSRFTOKEN) {
        return;
      } else {
        clearInterval(loopXsrf);
        return request;
      }
    }, 140);
  } else {
    return request;
  }

  let token = storage.get(constants.STORAGE_KEYS.TOKEN, Object);

  if (null !== token && typeof token === "string" && token != "") {
    if (typeof request.headers === "undefined") {
      request.headers = {};
    }

    request.headers[constants.XSRF_TOKEN] = token;
  }

  store.dispatch("apiLoading/setLoading", true);
  return request;
});

client.interceptors.response.use(
  (response) => {
    if (response.config.method.toLowerCase() == "get" && response.config.url.indexOf("auth/start") !== -1) {
      if (response.data && response.data.token) storage.set(constants.STORAGE_KEYS.TOKEN, response.data.token, Object);
    } else if (response.config.method.toLowerCase() == "get" && response.config.url.indexOf("auth/ping") !== -1) {
      storage.set(constants.STORAGE_KEYS.PING, response.data, Object);
    } else if (response.config.method.toLowerCase() == "get" && response.config.url.indexOf("cms/main-page-content") !== -1) {
      // @todo this has to be removed from here!!
      let data = response.data;
      let images = [];
      let counter = 0;
      if (data) {
        for (let index in data) {
          if (["TEASER1", "TEASER2", "TEASER3"].includes(data[index].tag)) {
            if (data[index].imageName != null && data[index].imageName != "") {
              images[counter] = constants.FE_URL + "/media/cms/images/" + data[index].imageName;
              counter++;
            }
          }
        }
        EventBus.emit("images", images);
      }
    }

    store.dispatch("apiLoading/setLoading", false);
    return response;
  },
  (error) => {
    const status = error.response.status;
    const trackingErrorInformationService = new tErrorInformationService();
    if (cookieService.isStatisticsGroupEnabled()) {
      trackingErrorInformationService.init();
      trackingErrorInformationService.setErrorValues(status, error.response?.statusText);
      trackingErrorInformationService.submit();
    }

    if (status === 401) {
      if (error.response.data && error.response.data.sessionTimeout) {
        store.dispatch("auth/logoutComplete");
        storage.clear();
        return;
      }
    }

    if (status == 402 || status === 401) {
      storage.clear();
      window.location.reload();
      return;
    }

    if (status === 429) {
      storage.clear();
      //redirect to route 429 if too many requests
      router.push({
        name: "429",
        params: {},
      }).then();
      return;
    }
    if (status === 500 && status === 400) {
      storage.clear();
      //redirect to route home if too many requests
      let notification = {
        code: "Das System ist auf ein unerwartetes Problem gestoßen. Bitte probiere es noch einmal.",
        type: "error",
      };
      store.dispatch("notification/set", notification).then();
      router.push("/").then();
    }

    if (status === 404) {
      store.dispatch("notification/set", null);
      store.dispatch("apiLoading/setLoading", false);
      router.push({name: "404"})
    }

    if (typeof error.response !== "undefined" && error.response.status >= 400 && error.response.status !== 404) {
      let notification = {
        code: "SERVER_ERROR",
        type: "error",
      };
      store.dispatch("notification/set", notification);
    }

    throw error;
  }
);

export default {
  async send(method, url, data, params, headers) {
    return client({
      url,
      method,
      headers,
      params,
      data,
    })
      .then(this.handleResponse)
      .catch(this.handleError);
  },
  async get(url, params, headers) {
    return await this.send("get", url, null, params, headers);
  },
  async post(url, data, headers) {
    return await this.send("post", url, data, null, headers);
  },
  async update(url, data, headers) {
    return await this.send("put", url, data, null, headers);
  },
  async delete(url, headers) {
    return await this.send("delete", url, null, null, headers);
  },
  async custom(method, url, data, params, headers) {
    return await this.execute(method, url, data, params, headers);
  },
  handleResponse(response) {
    if (response && response.data) return response.data;
    return null;
  },
  handleError(error) {
    return Promise.reject(error);
  },
};
