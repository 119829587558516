import orderApi from "../../backend/services/order";
export default {
  async getList({ commit }) {
    return new Promise((resolve, reject) => {
       orderApi.getList().then(data => {
         data.items.sort((a, b) => (b.created_at > a.created_at ? 1 : -1));
         commit("setList", data);
         resolve(data)
      }).catch(error => {
         reject(error)
       })
    });
    // const data = await orderApi.getList();
    // data.items.sort((a, b) => (b.created_at > a.created_at ? 1 : -1));
    // commit("setList", data);
    // return data;
  },
};
