import {createStore} from 'vuex'
import auth from './auth'
import cms from './cms'
import catalog from './catalog'
import cart from './cart'
import payment from './payment'
import expressCheckoutCart from './expressCheckoutCart'
import notification from './notification'
import account from './account'
import order from './order'
import sliderImages from './sliderImages'
import securepay from './securepay'
import apiLoading from './apiLoading'

const store = createStore({
    modules: {
        sliderImages,
        catalog,
        account,
        order,
        auth,
        cart,
        payment,
        expressCheckoutCart,
        cms,
        notification,
        securepay,
        apiLoading
    }
})

export default store
