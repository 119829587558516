import api from "@/backend/api";

export default {
  async getPaymentUrl(params) {
    let requestUrl = params.iframeUrl + "?value=" + params.paymentValue;
    if (params.mode) {
      requestUrl = requestUrl + "&mode=" + params.mode;
    }
    return await api.get(requestUrl);
  },

  async getSuccessPaymentUrl(params) {
    let requestUrl = "cashdiscount/process";
    return await api.post(requestUrl, params);
  },
};