import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store/store";
import i18n from "@/locales";
import date from "@/filters/date";
import amountFormatter from "@/filters/amountFormatter";
import numberFormatter from "@/filters/numberFormatter";
import api from "@/backend/api";
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp(App);
app.config.globalProperties.window = window;
(function () {
  try {
    app.config.globalProperties._satellite = _satellite;
  } catch (e) {
    console.log(e);
  }
})();
app.config.globalProperties.$filters = {
  formatDate: date,
  formatAmount: amountFormatter,
  formatNumber: numberFormatter,
};
app.use(store);
app.use(api);
app.use(router);

app.use(i18n);
app.use(api);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  },
});
app.mount("#app");
