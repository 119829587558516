import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/store";

import EventBus from "@/helpers/event-bus";
import tService from "@/service/tService";

const Home = () => import("@/views/Home");
const Category = () => import("@/views/Category");
const ProductDetails = () => import("@/views/ProductDetails");
const ShoppingCart = () => import("@/views/ShoppingCart");
const LandingPage = () => import("@/views/LandingPage");
const Brands = () => import("@/views/Brands");
const Manufactures = () => import("@/views/Manufactures");
const Redemption = () => import("@/views/Redemption");
const OrdersList = () => import("@/views/OrdersList");
const OrderSuccess = () => import("@/views/OrderSuccess");
const OrderFail = () => import("@/views/OrderFail");
const Order = () => import("@/views/Order");
const ExpressCheckoutOrder = () => import("@/views/ExpressCheckoutOrder");
const BargeldInfo = () => import(/* webpackChunkName: "bargeld" */ "@/views/BargeldInfo");
const BargeldForm = () => import(/* webpackChunkName: "bargeld" */ "@/views/BargeldForm");
const BargeldSuccess = () => import(/* webpackChunkName: "bargeld" */ "@/views/BargeldSuccess");
const BargeldFail = () => import(/* webpackChunkName: "bargeld" */ "@/views/BargeldFail");
const NotFound = () => import("@/views/NotFound");
const TooManyRequests = () => import("@/views/TooManyRequests");

const routes = [


  {
    path: "/429",
    name: "429",
    component: TooManyRequests,
    meta: {
      trackingName: 'too_many_requests_429',
      trackingSubCategory1: 'error',
    }
  },
  {
    path: "/:filter?",
    name: "home",
    component: Home,
    meta: {
      pageType: "home",
      loggerType: "shop",
      trackingName: 'home',
      trackingSubCategory1: '',
    },
    // beforeEnter: (to, from, next) => {
    //   if (validSearchPointsQuery(to)) {
    //     next('/404')
    //   }
    //   next()
    // },
  },
  {
    path: "/praemien/produkte:filter?",
    name: "landing-home",
    component: Home,
    meta: {
      pageType: "home",
      loggerType: "shop",
      trackingName: 'home',
      trackingSubCategory1: 'products',
    },
    beforeEnter: (to, from, next) => {
      if (validSearchPointsQuery(to)) {
        next('/404')
      }
      next()
    },

  },

  {
    path: "/praemien/kategorie/:category/:filter?",
    name: "products",
    component: Home,
    meta: {
      pageType: "category",
      loggerType: "shop.productCategory",
      trackingName: 'home',
      trackingSubCategory1: 'products',
      trackingSubCategory2: 'category_view'
    },
  },
  {
    path: "/praemien/:sku(\\d+)",
    name: "praemien",
    component: Home,
    meta: {
      loggerType: "shop",
      trackingName: 'home',
    },
  },
  {
    path: "/praemien/produkt/:seo_url",
    name: "product-details",
    component: ProductDetails,
    meta: {
      loggerType: "shop.productDetails",
      trackingName: 'detail_view',
      trackingSubCategory1: 'products',
    },
    beforeEnter: (to, from, next) => {

      if (to?.params?.seo_url.length > 0 && to?.params?.seo_url.indexOf('?') === 1) {
        next('/404')
      }
      next()
    }
  },
  {
    path: "/praemien/warenkorb",
    name: "shopping-cart",
    component: ShoppingCart,
    meta: {
      loggerType: "shop.cart",
      trackingName: 'cart_view',
      trackingSubCategory1: 'shoppingcart',
    },
  },
  {
    path: "/praemien/mein-warenkorb",
    name: "my-shopping-cart",
    component: ShoppingCart,
    meta: {
      requiresAuth: true,
      loggerType: "shop.myCart",
      trackingName: 'cart_view',
      trackingSubCategory1: 'shoppingcart',
    },
  },
  {
    path: "/praemien/bestellen",
    name: "bestellen",
    component: Order,
    meta: {
      requiresAuth: true,
      afterLoginRedirectsTo: "/praemien/mein-warenkorb",
      loggerType: "shop.checkout",
      pageType: "bestellen",
      trackingName: 'address_payment',
      trackingSubCategory1: 'checkout',
    },
  },
  {
    path: "/praemien/express-bestellen",
    name: "express-bestellen",
    component: ExpressCheckoutOrder,
    meta: {
      requiresAuth: true,
      loggerType: "shop.checkout",
    },
  },

  {
    path: "/praemien/praemienaktionen/praemien-marken",
    name: "brands",
    component: Brands,
    meta: {
      loggerType: "pages.brands",
      trackingName: 'additional_pages_brands',
    },
  },
  {
    path: "/punkte-einloesen",
    name: "redemption",
    component: Redemption,
    meta: {
      loggerType: "misc.redemption",
      pageType: "punkte-einloesen",
      trackingName: 'additional_pages_redemption',
    },
  },
  {
    path: "/praemien/praemienaktionen/hersteller",
    name: "manufactures",
    component: Manufactures,
    meta: {
      loggerType: "pages.manufacturers",
      trackingName: 'additional_pages_manufacturers',
      trackingSubCategory1: 'pages',
    },
  },
  {
    path: "/praemien/praemienaktionen/:url_key",
    name: "landing-page",
    component: LandingPage,
    meta: {
      pageType: "landing-page",
      loggerType: "shop.landings",
      trackingName: 'landing_page',
      trackingSubCategory1: 'products',
      trackingSubCategory1: 'landingpage',
    },
  },
  {
    path: "/praemien/meine-bestellungen",
    name: "my-orders",
    component: OrdersList,
    meta: {
      requiresAuth: true,
      loggerType: "shop.orderHistory",
      trackingName: 'order_history',
      trackingSubCategory1: 'profile'
    },
  },
  {
    path: "/praemien/bestellt",
    name: "order-success",
    component: OrderSuccess,
    props: true,
    meta: {
      requiresAuth: true,
      afterLoginRedirectsTo: "/praemien/mein-warenkorb",
      loggerType: "shop.checkoutSuccess",
      trackingName: 'confirmation',
      trackingSubCategory1: 'checkout',
    },
  },
  {
    path: "/praemien/fehler",
    name: "order-fail",
    component: OrderFail,
    meta: {
      requiresAuth: true,
      afterLoginRedirectsTo: "/praemien/mein-warenkorb",
      loggerType: "shop.checkoutFailure",
      trackingName: 'checkout_failure',
      trackingSubCategory1: 'checkout'
    },
  },
  {
    path: "/bargeld",
    name: "bargeld",
    component: OrderFail,
    meta: {
      loggerType: "cash",
      pageType: "bargeld",
      trackingName: 'cash_discount',
      trackingSubCategory1: 'cash_discount',
    },
  },
  {
    path: "/bargeld/info",
    name: "bargeld-info",
    component: BargeldInfo,
    meta: {
      loggerType: "cash.discount",
      pageType: "bargeld",
      trackingName: 'cash_discount_info',
      trackingSubCategory1: 'cash_discount',
    },
  },
  {
    path: "/bargeld/form",
    name: "bargeld-form",
    component: BargeldForm,
    meta: {
      requiresAuth: true,
      loggerType: "cash.form",
      pageType: "bargeld",
      trackingName: 'cash_discount_form',
      trackingSubCategory1: 'cash_discount',
    },
  },
  {
    path: "/bargeld/success",
    name: "bargeld-success",
    component: BargeldSuccess,
    meta: {
      loggerType: "cash.success",
      pageType: "bargeld",
      trackingName: 'cash_discount_success',
      trackingSubCategory1: 'cash_discount',
    },
  },
  {
    path: "/bargeld/fail",
    name: "bargeld-fail",
    component: BargeldFail,
    meta: {
      loggerType: "cash.fail",
      pageType: "bargeld",
      trackingName: 'cash_discount_failure',
      trackingSubCategory1: 'cash_discount',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: { title: "Payback AT - 404", trackingName: 'error_page_404', trackingSubCategory1: 'error', },
  }

];

const validSearchPointsQuery = (to) => {
  return (
    (to.params?.filter?.length > 0 && (to.params?.filter?.indexOf('searchTerm=') === -1 || to.params?.filter?.indexOf('filterPointsMin=') === -1)) ||
    (Object.keys(to.query).length > 0 && (!Object.keys(to.query).includes('searchTerm') && !Object.keys(to.query).includes('filterPointsMin')))
  );
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
    }
    else if (savedPosition) {
      return savedPosition
    }
    else {
      return { top: 0 };
    }
  },
});
router.beforeEach(async (to, from, next) => {

  const isLoggedIn = await store.getters["account/isLoggedIn"]

  EventBus.emit("routeDetails", from);
  // If the route is shopping cart and the user is logged in redirect the user to my-shopping-cart
  if (to.name === "shopping-cart" && isLoggedIn === true) {
    next({
      name: "my-shopping-cart",
    });
  }

  if (to.meta && to.meta.requiresAuth && !isLoggedIn) {

    // Check if after login the user should be redirected to another route
    const returnUrl = to.meta && to.meta.afterLoginRedirectsTo ? to.meta.afterLoginRedirectsTo : to.path;

    // Login type is either bargeld or normal
    const loginType = to.meta && to.meta.loginType ? to.meta.loginType : "normal";

    const requireAuth = to.meta.requiresAuth
    const data = {
      returnUrl: returnUrl,
      loginType: loginType,
      requireAuth: requireAuth
    };
    // added redirection to login
    await store.dispatch("auth/redirectToLoginPage", data);
  }
  if (to.meta && to.meta.pageType === 'home' && from.meta.pageType === "category") {
    const isFooterCategoryClicked = await store.getters["catalog/getFooterCategoryClick"]
    if (isFooterCategoryClicked) {
      setTimeout(() => {
        const element = document.querySelector('.category-footer-section');
        let headerOffset = 150;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.scrollY - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
        store.dispatch("catalog/setFooterCategoryClick", false);
      }, 800);

    }

  }
  next();
});
export default router;