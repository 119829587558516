import api from '@/backend/api'

export default {
    /**
     GET   /api/auth/start
     */
    async start() {
        return await api.get('auth/start')
    },
    /**
     GET   /api/auth/ping
     */
    async ping() {
        return await api.get('auth/ping')
    },
    /**
     GET   /api/auth/login-url
     */
    async getLoginData() {
        return await api.get('auth/login-url')
    },
    async deleteLoginCookie() {
        return await api.get('auth/deleteLoginCookie')
    },
    async getLogoutCookie() {
        return await api.get('authenticate/logoutCookie')
    }
}