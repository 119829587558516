import tRewardsShopService from "@/service/tRewardsShopService";
import tProductInformationService from "@/service/tProductInformationService";
import tService from "@/service/tService";
import tCartInformationService from "@/service/tCartInformationService";
import storage from "@/helpers/storage";

export default {
  setProducts(state, products) {
    if (
      (Array.isArray(state.products) && state.products.length == 0) ||
      !state.products ||
      products.count < parseInt(state.pagination.paginationPageSize) ||
      (state.pagination.paginationCurrentPage == 1 && state.filters && Object.keys(state.filters).length > 0)
    ) {
      state.products = products?.items;
      state.count = products?.count;
    } else {
      let newProducts = products?.items;
      for (let product in newProducts) {
        state.products.push(newProducts[product]);
      }
      if (parseInt(products?.count) === state.products.length) {
        state.productsLoaded = true;
      }
    }
  },
  setProduct(state, product) {
    state.product = product;
  },
  setCategory(state, category) {
    state.category = category;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  emptyProductsList(state) {
    state.products = [];
  },
  setProductsLoaded(state, flag) {
    state.productsLoaded = flag;
  },

  setFilters(state, filters) {
    state.filters = filters;
  },
  setLandingPage(state, landingPage) {
    state.landingPage = landingPage;
  },
  setBrands(state, brands) {
    state.brands = brands;
  },
  setContacts(state, contacts) {
    state.contacts = contacts;
  },
  setLandingPageProducts(state, product) {
    state.products.push(product);
  },
  setCategoryName(state, categoryName) {
    state.categoryName = categoryName;
  },
  setFooterCategoryClick(state, status) {
    state.footerCategoryClicked = status
  },
  trackProduct(state, data) {
    // if ("filterCoOrderProducts" in state.filters) {
    //   return;
    // }
    const tRShopService = new tRewardsShopService();
    const tPInformationService = new tProductInformationService();
    tRShopService.init();


    let count = 1;
    let subCategoryValue = "";

    if (Array.isArray(data)) {
      count = data.length;
      for (let index in data) {
        tPInformationService.setProduct(state.categories, data[index]);
      }
    } else {
      tPInformationService.setProduct(state.categories, data);
    }

    tPInformationService.setCategory(state.categoryName || null);
    const persistentData = {
      pageName: state.categoryName
    }

    tRShopService.setRewardsFilter(state.filters["filterPointsMin"] || null, state.filters["filterPointsMax"] || null, state.categoryName || null, state.product?.brand?.name);
    if ("searchTerm" in state.filters) {
      tRShopService.setOnSiteValues(state.filters["searchTerm"] || null, count);
    } else {
      tRShopService.setOnSiteValues(null, count);
    }

    if ("sortOrderDirection" in state.filters) {
      tRShopService.setRewardsView(state.filters["sortOrderDirection"] || null);
    }

    if (!!storage.get("parent-category-id") && !!storage.get("selected-subcategory-parent-id")) {
      if (storage.get("parent-category-id") === storage.get("selected-subcategory-parent-id") && storage.get("selected-category-type") === "subCategory") {
        subCategoryValue = "category_" + storage.get("parent-category-name") + ":" + storage.get("selected-category-name");
      } else {
        subCategoryValue = storage.get("selected-category-name");
      }
    }

    tPInformationService.tService.setCategory({
      primaryCategory: "rewards",
      subCategory: subCategoryValue,
    });

    tPInformationService.tService.setPageInfo(tRShopService.pageInfoInstance);
    tPInformationService.tService.setPageInfo(persistentData);

    tPInformationService.submit(tRShopService);
  },

  trackLandingPage(state, data) {
    const trackingService = new tService();
    const tCrtInformationService = new tCartInformationService();
    tCrtInformationService.init();
    trackingService.init(tCrtInformationService.get());

    let persistentPage = {
      pageName: data.title,
    };

    trackingService.setPageInfo(persistentPage);

    trackingService.track();
  },
};
