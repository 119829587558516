import { isArray, isObject, isString, each } from "lodash-es";
import tService from "@/service/tService";
import tCartInformationService from "@/service/tCartInformationService"
import parseTrackingValue from "@/service/parseTrackingValue";
import { cookieService } from "@/service/cookieConsentService";
import router from "@/router";
import store from "@/store/store";

export default class tProductInformationService {
  constructor() {
    this.tService = new tService();
    this.tCartInformationService = new tCartInformationService();
    this.products = [];
    this.defaultCategory = "";
    this.productTemplate = {
      productInfo: {
        productID: "",
        productName: "",
      },
      category: {
        primaryCategory: "",
      },
      price: {
        totalPoints: "",
        pointsPAC: "",
        cashPAC: "",
      },
    };

  }

  init(forceInit) {
    if (!window.digitalData_rewardsshop || (forceInit === true && cookieService.isStatisticsGroupEnabled())) {
      this.tService.tServiceInit();
    }
    this.products = [];
    this.defaultCategory = "";

    if (window.digitalData_rewardsshop.product) {
      delete window.digitalData_rewardsshop.product;
    }
  }
  setProduct(allCategories, product) {
    if (!product) {
      return false;
    }
    let record = JSON.parse(JSON.stringify(this.productTemplate)); // = deep clone

    record.productInfo = {
      productID: String(product.sku),
      productName: product.name,
    };

    if (isArray(product.category_ids) && product.category_ids.length > 1) {
      const filteredCategories = allCategories?.children?.find((category) => category?.id == product?.category_ids[1]);
      let categoryName = filteredCategories?.name ?? "";

      record.category = {
        primaryCategory: categoryName,
      };
    }

    if (product.price.primary.DEFAULT) {
      record.price = {
        totalPoints: String(product.price.primary.DEFAULT.value_int),
      };

      if (product.remaining_price) {
        record.price.pointsPAC = String(product.remaining_price.points);
        record.price.cashPAC = String(product.remaining_price.currency);
      }
    }

    this.products.push(record);
  }
  setCategory(category) {
    if (isObject(category)) {
      this.defaultCategory = parseTrackingValue(String(category?.url_key), true);
    } else if (isString(category)) {
      this.defaultCategory = parseTrackingValue(String(category), true);
    }
  }
  get() {
    return this.products;
  }
  submit(tRewardsShopService) {
    const currentRoute = router?.currentRoute;
    const routerParam = currentRoute.value?.params
    const routerQuery = currentRoute.value?.query;
    const querySearchParams = routerQuery?.searchTerm;
    if (cookieService.isStatisticsGroupEnabled()) {

      let pageName = "";
      let persistentPage = {}
      if (querySearchParams) {
        persistentPage = {
          pageName: "searchresults",
        };
        this.tService.setCategory({
          primaryCategory: "rewards",
          subCategory: "search"
        });
      } else if (routerParam?.category) {
        this.tService.setCategory({
          primaryCategory: "rewards",
          subCategory: currentRoute.value.meta.trackingSubCategory1
        });
      } else {
        persistentPage = {
          pageName: currentRoute.value.meta.trackingName,
        };
        this.tService.setCategory({
          primaryCategory: "rewards",
          subCategory: currentRoute.value.meta.trackingSubCategory1
        });

      }
      if (isString(tRewardsShopService.defaultCategory) && tRewardsShopService.defaultCategory !== "") {
        each(this.products, function (product) {
          if(product){
            product.category.primaryCategory = parseTrackingValue(String(tRewardsShopService.defaultCategory, true));
          }
        });
      }
      this.tService.setProducts(this.products);
      this.tService.setPageInfo(persistentPage);
      setTimeout(() => {
        this.tService.track()
      }, 500);
    }
  }
}
