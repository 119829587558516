<template>
      <section class="warenkorb" @click="goToCart()">
            <span class="warenkorb__cart" v-if="totalNrOfItems">{{ totalNrOfItems }}</span>

            <img src="@/assets/img/icons/ic_shopping_cart.svg" alt="warenkorb" />
            <span class="cart-text" v-if="showText">Warenkorb</span>
      </section>
</template>

<script>
      import { useStore } from "vuex";
      import { computed } from "vue";
      import { useRouter } from "vue-router";

      export default {
            name: "ShoppingCartMenu",
            props: { showText: Boolean },
            setup() {
                  const store = useStore();
                  const router = useRouter();

                  const goToCart = () => {
                        store.dispatch("catalog/emptyProductsList");
                        setTimeout(function () {
                              router.push({
                                    name: "shopping-cart",
                                    params: {}
                              });
                        }, 500);
                  };

                  const totalNrOfItems = computed(() => {
                        let nums = store.getters["cart/totalNrOfItems"];
                        if (nums > 0) return nums;
                        return 0;
                  });

                  return {
                        goToCart,
                        totalNrOfItems
                  };
            }
      };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
      .warenkorb {
            @apply flex text-black flex-col justify-center items-center relative;
            &__cart {
                  @apply bg-badge-red text-white rounded-full text-xs w-5 h-5 flex justify-center items-center absolute;
                  top: -0.3rem;
                  right: 0.5rem;
            }
            .cart-text {
                  @apply text-gray-dark text-xs relative;
                  top: 6px;
                  @media screen and (max-width: 767px) {
                        font-size: 13px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-weight: 600;
                  }
                  @media screen and (max-width: 450px) {
                        font-size: 12px;
                  }
                  @media screen and (max-width: 380px) {
                        font-size: 11px;
                  }
            }
      }
      .page-nav-menu .warenkorb__cart,
      .top-menu-mobile .warenkorb__cart {
            top: -0.4rem;
            right: -0.5rem;
      }
</style>
