<template>
  <section class="top-menu-mobile">
    <div class="top-menu-mobile__container">
      <div class="grid grid-cols-2 border-b-2 border-blue">
        <div
          class="col-span-1 top-menu-mobile__search--icon"
          @click="toggleSearchField(), focusOnInput()"
        >
          <img src="../../assets/img/icons/ic_search_blue.svg" alt="suche" />
        </div>
        <div class="col-span-1 top-menu-mobile__cart">
          <shopping-cart-menu-top />
        </div>
      </div>
      <div
        class="col-span-1 top-menu-mobile__search--search-field"
        v-if="showSearch"
        :class="{ shown: showSearch, hidden: !showSearch }"
      >
        <text-input
          @onEnter="searchProduct"
          :value="searchText"
          :placeholder="'Prämien, Marken, ...'"
          cssClass="searchinput"
          :autofocus="autoFocus"
        ></text-input>
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {ref} from "vue";
import TextInput from "@/components/Inputs/TextInput";
import ShoppingCartMenuTop from "../ShoppingCart/ShoppingCartMenuTop.vue";
import EventBus from "@/helpers/event-bus";

export default {
  name: "TopMenuMobile",
  components: {
    ShoppingCartMenuTop,
    TextInput,
  },
  setup(props, { emit }) {
    const showSearch = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isCheckout = ref(false);
    const isCheckoutSuccess = ref(false);
    const filters = ref({});
    const searchText = ref("");
    const autoFocus = ref(false)

    isCheckout.value =
      route.name === "shopping-cart" ||
      route.name === "my-shopping-cart" ||
      route.name === "bestellen";

    //@todo what about bargeld?
    isCheckoutSuccess.value = route.name === "order-success";

    const hasHistory = () => {
      return window.history.length > 2;
    };
    const toggleSearchField = () => {
      showSearch.value = !showSearch.value;
    };
    const focusOnInput = () => {
      autoFocus.value = true
    };
    const searchProduct = (input) => {
      filters.value["searchTerm"] = input.trim();
      store.dispatch("catalog/setFilters", filters.value);
      store.dispatch("catalog/getProductsList");
      searchText.value = input;
      EventBus.emit("getSearchText", input);
      showSearch.value = false;
    };

    EventBus.on("getSearchText", (arg) => {
      searchText.value = arg;
    });

    const goback = async () => {
      await store.dispatch("sliderImages/setImages", { data: [], type: "" });
      if (hasHistory()) {
        if (
          route.name === "my-shopping-cart" &&
          JSON.stringify(window.history.state.back) === "null"
        ) {
          await router.push("/");
        } else {
          router.back();
        }
      } else {
        router.push("/");
      }
    };
    return {
      isCheckoutSuccess,
      isCheckout,
      goback,
      showSearch,
      toggleSearchField,
      searchProduct,
      filters,
      searchText,
      focusOnInput,
      autoFocus
    };
  },
};
</script>
<style lang="postcss" scoped>
.top-menu-mobile {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    @apply bg-white border-t border-blue relative top-0;
    z-index: 3;
    height: 60px;
    &__container {
      @apply text-gray-dark h-full;
      grid-area: main;
      a {
        @apply ml-10 text-blue;
      }
      .back-button {
        height: 90px;
        @apply flex text-black flex-col justify-center items-start;
        a {
          @apply ml-0;
        }
      }
      .shopping-cart {
        height: 90px;
        @apply flex text-black flex-col justify-center items-end;
      }
    }
    &__search--icon {
      @apply h-full border-r border-highlighted flex items-center justify-center bg-white;
      height: 60px;
      z-index: 4;
      img {
        width: auto;
        height: 36px;
      }
    }
    &__cart {
      @apply h-full flex items-center justify-center  bg-white;
      height: 60px;
      z-index: 4;
    }
    &__search--search-field {
      @apply bg-blue-light px-5 py-3 relative;
      top: -60px;
      overflow-y: hidden;
      height: 60px;
      z-index: 3;

      &.hidden {
        animation: slideDown 0.4s ease-in reverse backwards;
      }
      &.shown {
        animation: slideDown 0.4s ease-in forwards;
      }
    }
  }
  .notification {
    @apply bg-highlighted;
  }
  @keyframes slideDown {
    0% {
      height: 60px;
      top: -60px;
    }
    100% {
      height: auto;
      top: -1px;
    }
  }
}
</style>
