<template>
      <img async :alt="alt" :src="src" :title="title" :width="imgWidth > 0 ? imgWidth:''" :height="imgHeight" />
</template>

<script>
      import { ref, onMounted } from "vue";
      export default {
            props: {
                  alt: {
                        type: String
                  },
                  src: {
                        type: String
                  },
                  title: {
                        type: String
                  }
            },
            name: "Image",
            components: {},
            setup(props) {
                  const imgWidth = ref("");
                  const imgHeight = ref("");

                  onMounted(() => {
                        const imgSelector = document.querySelector(`img[src='${props.src}']`);
                        imgWidth.value = imgSelector?.clientWidth;
                        imgHeight.value = imgSelector?.clientHeight;
                  });
                  return {
                        imgWidth,
                        imgHeight
                  };
            }
      };
</script>
