export default function parseTrackingValue(value, toLowerCase) {

    let germanUmlautsReplaced = (value) => {
        value = value.replace(/\u00fc|\u00dc/g, 'ue');
        value = value.replace(/\u00f6|\u00d6/g, 'oe');
        value = value.replace(/\u00e4|\u00c4/g, 'ae');

        value = value.replace(/\u00df/g, 'ss');
        value = value.replace(/ /g, '_');

        return value;
    };

    if (toLowerCase === true) {
        value = value.toLowerCase();
    }

    return germanUmlautsReplaced(value)
}