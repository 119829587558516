class CookieConsentService {
  constructor(cookieConsent) {
    this.consentModuleEnabled = cookieConsent;
  }

  updateActiveGroups() {
    this.activeGroups = window.OptanonActiveGroups;
  }

  /**
   * @returns {boolean}
   *
   * Will return true in the following cases:
   * - cookie consent module is disabled
   * - cookie consent module is enabled and cookie with user preferences exists and the statistics cookies group was allowed by the user
   *
   * Will return false in the other cases
   */
  isStatisticsGroupEnabled() {
    this.updateActiveGroups();
    const statistics = "C0002";

    if (!this.consentModuleEnabled || (this.consentModuleEnabled && this.activeGroups && this.activeGroups.indexOf(statistics) !== -1)) {
      return true;
    }
    return false;
  }

  /**
   * @returns {boolean}
   *
   * Will return true in the following cases:
   * - cookie consent module is disabled
   * - cookie consent module is enabled and cookie with user preferences exists and the functional cookies group was allowed by the user
   *
   * Will return false in the other cases
   */
  isFunctionalGroupEnabled() {
    this.updateActiveGroups();
    const functional = "C0003";

    if (!this.consentModuleEnabled || (this.consentModuleEnabled && this.activeGroups && this.activeGroups.indexOf(functional) !== -1)) {
      return true;
    }
    return false;
  }

  /**
   * @returns {boolean}
   *
   * Will return true in the following cases:
   * - cookie consent module is disabled
   * - cookie consent module is enabled and cookie with user preferences exists and the marketing cookies group was allowed by the user
   *
   * Will return false in the other cases
   */
  isMarketingGroupEnabled() {
    this.updateActiveGroups();
    const marketing = "C0004";

    if (!this.consentModuleEnabled || (this.consentModuleEnabled && this.activeGroups && this.activeGroups.indexOf(marketing) !== -1)) {
      return true;
    }
    return false;
  }

}

export const cookieService = new CookieConsentService(true);
