export default function date(value, format) {
    let date = new Date(value);
    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
    }

    if (typeof format == 'undefined') {
        options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }
    }
    let full = new Intl.DateTimeFormat('de', options).format(date)
    return full;

}