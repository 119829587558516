export default Object.freeze({
  DB_DATETIME_FORMAT: "YYYY-MM-DD hh:mm:ss",
  DB_DATE_FORMAT: "YYYY-MM-DD",
  XSRF_TOKEN: "XSRF-TOKEN",
  FE_URL:
    process.env.VUE_APP_FE ||
    window.location.protocol + "//" + window.location.host,
  STORAGE_KEYS: {
    TOKEN: "auth_token",
    PING: "ping",
  },
  HEADER_IFRAME_URL:
    process.env.VUE_APP_FE + "/praemienshop/praemienshop-header",
  HEADER_IFRAME_SRC:
    process.env.VUE_APP_FE +
    "/praemienshop/praemienshop-header?frameId=pb-iframe0&parentUrl=http://payback-at-verify.de.uklo.office.eeft.com",
  IMAGE_DIR: {
    CMS: "/media/cms/images/",
    CATEGORIES: "/media/catalog/categories/",
    PRODUCT: "/media/catalog/product/",
    BRANDS: "/media/brands/",
  },
  REDEEM_RESTRICTIONS: {
    MIN_POINTS: 200,
    MAX_POINTS: 30000,
  },
  GOOGLE_MAPS_GEOCODE_URL: "https://maps.googleapis.com/maps/api/geocode/json",
  TARGETORIGIN: [
    process.env.VUE_APP_SEPAY
    // ToDos we need to handle this on environment level
    //'https://securepay.epayworldwide.com',
    //'https://securepaytest.epayworldwide.com'
  ],
  DD_COUNTRIES: [
    {
      id: "BE",
      val: "BE",
      label: "Belgien",
    },
    {
      id: "BG",
      val: "BG",
      label: "Bulgarien",
    },
    {
      id: "CH",
      val: "CH",
      label: "Schweiz",
    },
    {
      id: "DK",
      val: "DK",
      label: "Dänemark",
    },
    {
      id: "DE",
      val: "DE",
      label: "Deutschland",
    },
    {
      id: "EE",
      val: "EE",
      label: "Estland",
    },
    {
      id: "FI",
      val: "FI",
      label: "Finnland",
    },
    {
      id: "FR",
      val: "FR",
      label: "Frankreich",
    },
    {
      id: "GR",
      val: "GR",
      label: "Griechenland",
    },
    {
      id: "IE",
      val: "IE",
      label: "Irland",
    },
    {
      id: "IT",
      val: "IT",
      label: "Italien",
    },
    {
      id: "HR",
      val: "HR",
      label: "Kroatien",
    },
    {
      id: "LV",
      val: "LV",
      label: "Lettland",
    },
    {
      id: "LT",
      val: "LT",
      label: "Litauen",
    },
    {
      id: "MT",
      val: "MT",
      label: "Malta",
    },
    {
      id: "NL",
      val: "NL",
      label: "Niederlande",
    },
    {
      id: "AT",
      val: "AT",
      label: "Österreich",
    },
    {
      id: "PL",
      val: "PL",
      label: "Polen",
    },
    {
      id: "PT",
      val: "PT",
      label: "Portugal",
    },
    {
      id: "RO",
      val: "RO",
      label: "Rumänien",
    },
    {
      id: "SM",
      val: "SM",
      label: "San Marino",
    },
    {
      id: "SE",
      val: "SE",
      label: "Schweden",
    },
    {
      id: "SK",
      val: "SK",
      label: "Slowakei",
    },
    {
      id: "SI",
      val: "SI",
      label: "Slowenien",
    },
    {
      id: "ES",
      val: "ES",
      label: "Spanien",
    },
    {
      id: "CZ",
      val: "CZ",
      label: "Tschechische Republik",
    },
    {
      id: "HU",
      val: "HU",
      label: "Ungarn",
    },
    {
      id: "VA",
      val: "VA",
      label: "Vatikanstadt",
    },
    {
      id: "FO",
      val: "FO",
      label: "Faröer (zu Dänemark)",
    },
    {
      id: "CY",
      val: "CY",
      label: "Zypern",
    },
  ],
  CATEGORY_URLS: {
    "Bad & Wellness": "bad-wellness",
    "Bestseller": "bestseller",
    "Bücher & Zeitschriften": "zeitschriften",
    "Gutscheine": "gutscheine",
    "Haus,  Wohnen & Garten": "haus-wohnen-garten",
    "Kinderträume & Spiele": "kindertraeume-spiele",
    "Kochen & Genuss": "kochen",
    "Alle Prämien": "alle-praemien",
    "Multimedia & Technik": "multimedia-technik",
    "Neue Prämien": "neue-praemien",
    "Sale": "sale",
    "Sport,  Freizeit & Reisen": "sport-freizeit-reisen"
  }

});
