import api from '@/backend/api'

export default {
  get() {
    return api.get("/quote/express-checkout-cart")
  },
  deleteItem(itemId) {
    return api.delete(`/quote/express-checkout-cart/item/${itemId}`)
  },
  updateItem(item) {
    return api.update("/quote/express-checkout-cart/item", item)
  },
  addItem(item) {
    return api.post("/quote/express-checkout-cart/item", item)
  },
  payment(item) {
    return api.post("/quote/express-checkout-cart/payment", item)
  },
  submitOrder(data) {
    return api.post('checkout/express-checkout-validate', data)
  }
}
