import { clone, isNumber, isString } from "lodash-es";
import tService from "@/service/tService";
import parseTrackingValue from "@/service/parseTrackingValue";
import { cookieService } from "@/service/cookieConsentService";

export default class tRewardsShopService {
  constructor() {
    this.tService = new tService();
    this.pageInfoInstance = {
      attributes: {
        rewardsFilter: {
          pointsMin: undefined,
          pointsMax: undefined,
          category: "alle_kategorien_&_marken",
          brand: undefined
        },
        rewardsView: 'list', // 'grid_' + $rootScope.sortOrderName.toLowerCase(),
      },
      onsiteSearchTerm: undefined,
      onsiteSearchResults: undefined,
    };
    this.pageInfo = clone(this.pageInfoInstance);
  }

  init() {
    if (cookieService.isStatisticsGroupEnabled()) {
      this.tService.init();
      this.pageInfo = clone(this.pageInfoInstance);
    }
  }

  setOnSiteValues(searchTerm, searchResultCount) {
    if (isString(searchTerm)) {
      this.pageInfo.onsiteSearchTerm = searchTerm;
    }
    if (isNumber(searchResultCount)) {
      this.pageInfo.onsiteSearchResults = searchResultCount;
    }
  }

  setRewardsFilter(minPoints, maxPoints, category, brand) {
    if (isNumber(minPoints)) {
      this.pageInfo.attributes.rewardsFilter.pointsMin = String(minPoints);
    }
    if (isNumber(maxPoints)) {
      this.pageInfo.attributes.rewardsFilter.pointsMax = String(maxPoints);
    }
    if (isString(category)) {
      this.pageInfo.attributes.rewardsFilter.category = parseTrackingValue(category, true);
    }
    if (brand) {
      this.pageInfo.attributes.rewardsFilter.brand = brand;
    }
  }

  setRewardsView(rewardsView) {
    this.pageInfo.attributes.rewardsView = "grid_" + parseTrackingValue(rewardsView, true);
  }

  get() {
    return this.pageInfo;
  }

  submit() {
    if (cookieService.isStatisticsGroupEnabled()) {
      this.tService.setPageInfo(this.pageInfo);
      this.tService.track();
    }
  }
}
