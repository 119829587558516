import { createI18n } from 'vue-i18n'
import deLocale from '@/locales/de-de.json'

const i18n = createI18n({
  legacy: false, // you must specify 'legacy: false' option
  locale: 'de-de',
  fallback: 'de-de',
  messages: {
    'de-de': deLocale,
  },
})

export const supportedLocales = ['en-en', 'de-de']

export const loadLocale = async (locale) => {
  const messages = await import(`@/locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages)
  i18n.global.locale.value = locale
}

export default i18n
