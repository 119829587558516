<template>
  <div class="image_header">
    <div class="wrapper">
      <a :href="images[0]?.url" target="_blank" :title="images[0]?.title">
        <div class="content" :style="{ 'background-image': `url(${images[0]?.stripe})` }">
          <app-image :alt="images[0]?.title" class="flex flex-col justify-center items-center" :src="images[0]?.imageLink" />
        </div>
      </a>
    </div>
  </div>
</template>
<script>
  import { useStore } from "vuex";
  import { computed, ref } from "vue";
  import { useRoute } from "vue-router";
  import AppImage from "@/components/Image/Image";

  export default {
    components: {
      AppImage,
    },
    setup(props) {
      const store = useStore();
      const route = useRoute();
      const stripeLink = ref("");
      const logEvents = (state, ev) => {
        stripeLink.value = ev.nextSlide.content;
      };
      const redirectToUrl = (url) => {
        url ? window.location.assign(url) : "";
      };

      const images = computed(() => {
        return store.getters["sliderImages/getImages"];
      });

      const setDefaultBackground = (images) => {
        stripeLink.value = images[0]?.stripe;
      };

      return {
        images,
        redirectToUrl,
        stripeLink,
        logEvents,
        setDefaultBackground,
      };
    },
    props: {
      thumbnails: Array,
      modelValue: { type: Number, default: 0 },
      fixedHeight: { type: Number, default: 480 },
      title: { type: String },
      breakpoints: {
        767: {
          fixedHeight: 480,
          //desktop version 480px
        },
      },
    },
  };
</script>

<style lang="postcss">
  .image_header {
    height: 260px;
    background-repeat: repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: contain;
  }

  .image_header .wrapper {
    height: 260px;
    width: auto;
    position: relative;
    align-items: center;

    .content {
      width: auto;
      height: 260px;
      justify-content: center;
      align-content: center;
      display: grid;
      overflow: hidden;

      img {
        max-height: 260px;
        overflow: hidden;
        width: max-content;
        max-width: 2000px;
      }
    }
  }

  @media (max-width: 768px) {
    .image_header,
    .image_header .wrapper {
      height: 200px;
    }
    .image_header .wrapper .content {
      height: 200px;
      img {
        width: 960px;
        max-width: 2000px;
      }
    }
  }

  @media (max-width: 460px) {
    .image_header,
    .image_header .wrapper {
      height: 160px;
    }
    .image_header .wrapper .content {
      height: 160px;
      img {
        width: max-content;
        max-width: 2000px;
      }
    }
  }
</style>
