export default {
  getProducts(state) {
    return state.products;
  },
  getProductsCount(state) {
    return state.count;
  },
  getProduct(state) {
    return state.product;
  },
  getCategory(state) {
    return state.category;
  },
  getCategories(state) {
    return state.categories;
  },
  getFooterCategoryClick(state) {
    return state.footerCategoryClicked;
  },
  getFilters(state) {
    return state.filters;
  },
  getLandingPage(state) {
    return state.landingPage;
  },
  getBrands(state) {
    return state.brands;
  },
  getContacts(state) {
    return state.contacts;
  },
  getProductsLoaded(state) {
    return state.productsLoaded;
  },
};
