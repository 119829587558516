<template>
      <footer-default></footer-default>
      <!-- <FooterPb v-if="showFooter()" /> -->
</template>

<script>
      import { useRoute } from "vue-router";
      import FooterDefault from "./FooterDefault";
      import FooterPb from "./FooterPb";

      export default {
            name: "Footer",
            props: {
                  msg: String
            },
            components: { FooterDefault, FooterPb },
            setup() {
                  const route = useRoute();
                  // const showFooter = () => {
                  //   return route.meta.pageType == "bargeld";
                  // };

                  return {
                        // showFooter,
                  };
            }
      };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
