<template>
  <div class="text-input__row">
    <label v-if="label"
      >{{ label }}&nbsp;<span v-if="requiredField">&#42;</span></label
    >
    <input
      v-on:input="
        acceptNumberOnly();
        acceptNumberOnly();
        onInput;
      "
      v-on:keyup.enter="onEnter"
      :type="inputType"
      :title="title"
      v-model="innerValue"
      :placeholder="placeholder"
      :number-only="numberOnly"
      :autocomplete="autocomplete"
      :maxlength="maxlength"
      :readonly="readonly"
      :disabled="disabled"
      :name="name"
      :class="cssClass"
      :autofocus="autofocus"
    />
  </div>
</template>

<script>

export default {

  name: "TextInput",
  data: function () {
    return {
      innerValue: this.quantity,
      showDescription: false,
    };
  },
  components: {},

  watch: {
    value(newVal) {
      this.updateInputVal(newVal);
    },
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    pattern: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    cssClass: {
      type: String,
      default: "input-field",
    },
    numberOnly: {
      type: Boolean,
      default: false,
    },
    quantity: Number,
    // must be included in props
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    requiredField() {
      return this.rules.indexOf("required") !== -1;
    },
    computedName() {
      return this.name ? this.name : this.label;
    },

  },
  methods: {
    acceptNumberOnly() {
      if (this.numberOnly === true) {
        var x = this.innerValue.replace(/[^\d]+/g, "");
        this.innerValue = x;
      }
    },
    onInput(event) {
      this.$emit("input", event.target.value);
    },
    onEnter(event) {
      this.$emit("onEnter", event.target.value);
    },
    updateInputVal: function (event) {
      this.innerValue = event;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }

  },
};
</script>
<style lang="postcss" scoped>
.text-input__row {
  @apply flex justify-between items-start text-left w-full flex-col;
  @screen mobileM {
    @apply items-center flex-row;
  }
  label {
    @apply w-1/3  font-bold text-lg text-blue;
  }

  input {
    @apply border rounded-md border-highlighted p-2 w-full text-black mt-2;
    height: 54px;
    @screen mobileM {
      @apply w-2/3;
    }
    @media screen and (max-width: 767px) {
      filter: drop-shadow(0px 1.5px 2px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12));
      border: none;
      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      filter: drop-shadow(0px 1.5px 2px rgba(0, 0, 0, 0.25))
        drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
    }
  }
  input:only-child {
    @apply w-full;
  }
}
.text-input__row input.searchinput {
  background: url(../../assets/img/icons/ic_search.svg) no-repeat;
  background-position: 11px center;
  background-color: #fff;
  padding-left: 40px;
  margin-top: 0;
  font-size: 16px;
}
.text-input__row input.points-input {
  background: url(../../assets/img/icons/ic_points_op_black.svg) no-repeat;
  background-size: 24px;
  @apply font-paybackHeading;
  background-position: right 11px center;
  background-color: #fff;
  padding-right: 40px;
  margin-top: 0;
  font-size: 20px;
  line-height: 22px;
}
input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
</style>
