import constants from "@/helpers/constants";

export default {
  setImages({ commit }, payload) {
    let images = [];
    let counter = 0;
    const isDesktop = window.matchMedia("(min-width: 767px)").matches;

    if (payload.type == "CMS") {
      if (images) {
        for (let index in payload.data) {
          if (["TEASER1", "TEASER2", "TEASER3", "TEASER4"].includes(payload.data[index].tag)) {
            let imagePath = "";
            let stripImage = "";

            if (isDesktop && payload.data[index].imageName != null && payload.data[index].imageName != "" && payload.data[index].mobileOnly != 1) {
              const imageSourceDesktopPrefix = payload.data[index].imageName.indexOf("http") === -1 ? constants.FE_URL + constants.IMAGE_DIR.CMS : "";
              imagePath = imageSourceDesktopPrefix + payload.data[index].imageName;
              stripImage = payload.data[index]?.stripe;
            } else if (!isDesktop && payload.data[index].imageName != null && payload.data[index].imageName != "") {
              const imageSourceMobilePrefix = payload.data[index].mobile.indexOf("http") === -1 ? constants.FE_URL + constants.IMAGE_DIR.CMS : "";
              imagePath = imageSourceMobilePrefix + payload.data[index].mobile;
              stripImage = payload.data[index]?.mobileStripe;
            } else {
              continue;
            }

            images[counter] = {
              imageLink: imagePath,
              url: payload.data[index]?.url,
              title: payload.data[index]?.tag,
              text: payload.data[index]?.text,
              stripe: stripImage,
            };
            counter++;
          }
        }
      }
    } else if (payload.type == "PRODUCT") {
      if (payload.data && payload.data.images?.length > 0) {
        for (let index in payload.data.images) {
          if (payload.data.images[index].identifier != null && payload.data.images[index].identifier != "") {
            images[counter] = payload.data.images[index].identifier;
            counter++;
          }
        }
      }
      else {
        for (let index in payload.data.thumbnail) {
          if (payload.data.thumbnail[index].identifier != null && payload.data.thumbnail[index].identifier != "") {
            images[counter] = payload.data.thumbnail[index].identifier;
            counter++;
          }
        }
      }
    } else if (payload.type == "LANDING_PAGE" && payload.data) {
      let imagePath = "";
      if (payload.data.headerImageDesktop && isDesktop) {
        imagePath = payload.data.headerImageDesktop;
      } else if (payload.data.headerImageMobile && !isDesktop) {
        imagePath = payload.data.headerImageMobile;
      }
      images[0] = { imageLink: imagePath, title: payload.data.headerImageText, url: payload.data.headerImageUrl };
    } else if (payload.type == "CATEGORY" && payload?.data) {
      let imagePath = "";
      if (payload.data?.big_image && isDesktop) {
        const imageSourceDesktopPrefix = payload.data.big_image.indexOf("http") === -1 ? constants.FE_URL + constants.IMAGE_DIR.CATEGORIES : "";
        imagePath = imageSourceDesktopPrefix + payload.data.big_image;
      } else if (!isDesktop && payload.data?.mobile_image) {
        const imageSourceMobilePrefix = payload.data.mobile_image.indexOf("http") === -1 ? constants.FE_URL + constants.IMAGE_DIR.CATEGORIES : "";
        imagePath = imageSourceMobilePrefix + payload.data.mobile_image;
      }
      images[0] = {
        imageLink: imagePath,
        title: payload.data?.category_mouse_over ?? "",
        url: payload.data?.category_link,
      };
    } else {
      images[0] = {
        imageLink: "./img/TEASER_empty.png",
      };
    }
    commit("setImages", images);
    return images;
  },
};
