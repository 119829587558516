import cmsApi from "../../backend/services/cms";
import store from "@/store/store";

export default {
  async getMainPageContents({ commit }) {
    const data = await cmsApi.getMainPageContents();
    store.dispatch("sliderImages/setImages", { data: data, type: "CMS" });
    commit("setMainPageContents", data);
    return data;
  },
  async getRightSideLinks({ commit }) {
    const data = await cmsApi.getRightSideLinks();
    commit("setRightSideLinks", data);
    return data;
  },
};
