<template>
      <section>
            <iframe width="100%" overlay-width="1280" scrolling="no" id="footer-iframe" :src="`https://e1et-www.payback.at/bargeld-footer?frameId=pb-iframe0&amp;parentUrl=${feUrl}`" style="width: 1px; min-width: 100%; height: 384px; min-height: 384px; max-height: 384px; border: 0" title="footer payback"></iframe>
      </section>
</template>

<script>
      export default {
            name: "FooterPb",
            props: {
                  msg: String
            },
            components: {},
            data() {
                  return {
                        feUrl: process.env.VUE_APP_FE
                  };
            }
      };
</script>
