let counter = 0
export default {
  setLoading({ commit }, response) {
   if (response === true) {
      counter++
      commit("setLoading", response);
    }
    else {
      counter--
      if (counter === 0) {
        commit("setLoading", response);
      }
    }

  }
}