import authService from "@/backend/services/auth";
import router from '@/router'
import store from "@/store/store";

export default {
  async executeStart({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
       authService.start().then(response => {
         commit("setStart", response);
         commit("isStarting", false);
         dispatch("executePing");
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })

    // const data = await authService.start();
    // commit("isStarting", false);
    // commit("setStart", data);
    // await dispatch("executePing");

  },
  async executePing({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      authService.ping().then(response => {
        if (response && response.login_token_valid && response.login_token_valid == false) {
          resolve(dispatch("logout"));
        } else {
          commit("setPing", response);
          store.dispatch("account/setLoggedIn", response)
          commit("isStarting", true);
          resolve(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  async redirectToLoginPage({ rootGetters }, data) {

    setTimeout(async ()=>{
      const isStarting = rootGetters["auth/isStarting"]
      const isLoggedIn = rootGetters["account/isLoggedIn"]

      if (isLoggedIn === false) {
        // Get the login url data from backend
        const loginData =  await authService.getLoginData();

        const baseLoginUrl = data.loginType === "normal" ? loginData.url : loginData.bargeld_url
        if (isStarting) {
          location.href = baseLoginUrl + loginData.return_url_param + '=' + window.location.origin + data.returnUrl;
        }
      }
    },1000)

  },
  async getLogoutCookie() {
    return await authService.getLogoutCookie();
  },
  async logoutComplete({ dispatch }) {
    const currentRoute = router.currentRoute;
    if (currentRoute.meta && currentRoute.meta.requiresAuth === true) {
      await dispatch("getLogoutCookie")
      window.location.reload();
    } else {
      // No login required
      await authService.deleteLoginCookie();
      await dispatch("getLogoutCookie")
    }
  }

  // @todo this is copied from the old pb and needs to be rewritten but I am not sure when this piece of code is executed
  // var logout = function ()
  // {
  //   //redirect to the logout page
  //   if (!_.isUndefined($rootScope.customBranding.logoutURL)
  //       && !_.isNull($rootScope.customBranding.logoutURL))
  //   {
  //     $window.location.href = $rootScope.customBranding.logoutURL;
  //   }
  //   else
  //   {
  //     $log.error('Logout Failed');
  //   }
  // };
}