<template>
  <div v-if="text" class="chip">
    <div class="chip__content">
      <div class="chip__content--text">
        {{ text }}
      </div>
      <div @click="$emit('close')" class="chip__content--close">
        <div v-show="closeable">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="chip__content--close"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.5 8C0.5 3.8525 3.8525 0.5 8 0.5C12.1475 0.5 15.5 3.8525 15.5 8C15.5 12.1475 12.1475 15.5 8 15.5C3.8525 15.5 0.5 12.1475 0.5 8ZM10.6925 11.75L11.75 10.6925L9.0575 8L11.75 5.3075L10.6925 4.25L8 6.9425L5.3075 4.25L4.25 5.3075L6.9425 8L4.25 10.6925L5.3075 11.75L8 9.0575L10.6925 11.75Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ["text", "closeable"],
  emits: ["close"],
  name: "Chip",
  components: {},
  setup() {
    return {};
  },
};
</script>
<style lang="postcss" scoped>
.chip {
  @apply inline-block justify-center items-center m-1 mt-4 mb-0 py-1 pr-2 pl-3 rounded-full text-white bg-blue border;
  &__content {
    @apply w-full h-full flex justify-center items-center;
    &--text {
      @apply relative leading-none max-w-full flex-initial float-left;
      font-family: PAYBACKLight, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      top: 1px;
    }
    &--close {
      @apply flex flex-auto flex-row-reverse float-right ml-1;
      &-button {
        @apply cursor-pointer rounded-full w-4 h-4;
      }
    }
  }
}
</style>