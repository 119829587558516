import api from "@/backend/api";

export default {
  get() {
    return api.get("/quote/cart");
  },
  deleteItem(itemId) {
    return api.delete(`/quote/cart/item/${itemId}`);
  },
  updateItem(item) {
    return api.update("/quote/cart/item", item);
  },
  addItem(item) {
    return api.post("/quote/cart/item", item);
  },
  payment(item) {
    return api.post("/quote/cart/payment", item);
  },
  logger(data) {
    return api.post("logger", data);
  },
  validateVoucher(data) {
    return api.post("validate-voucher", data);
  },
  validateExpressVoucher(data) {
    return api.post("validate-voucher-express", data);
  },
  submitOrder(data) {
    return api.post("checkout/validate", data);
  },
  getLastOrder() {
    return api.get("checkout/lastorder");
  },
};
