import { useCartDefaultActions } from "@/composables/useCartDefaultActions";
import cartApi from "@/backend/services/cart";
import store from "@/store/store";

export default {
  ...useCartDefaultActions(cartApi),
  async deleteItem({ dispatch, commit, getters }, item) {
    const cartItems = getters.cart.items;
    var costs = [];
    let costsChanged = false;
    // Track the cost of each item in the current cart
    cartItems.forEach((element) => {
      // We don't need to keep track of the item that is being removed
      if (item.id != element.id) {
        costs[element.id] = element.total;
      }
    });

    // Delete the item
    const response = await cartApi.deleteItem(item.id);

    commit("setCart", response);

    // Compare the costs array with the updated cart
    var costs = [];
    cartItems.forEach((element) => {
      if (costs[element.id] != null && costs[element.id] !== element.total) {
        costsChanged = true;
      }
    });

    // If the costs have changed (means a cooproduct was removed), we should display a message to the user
    if (costsChanged) {
      let notification = {
        code: "COORDER_CHANGED",
        type: "info",
      };
      dispatch("notification/set", notification, { root: true });
    }
  },

  async logger({ commit }, params) {
    const data = await cartApi.logger(params);
    commit("setLogger", data); 
    return data;
  },

  async emptyCart({ commit }) {
    commit("setCart", {});
  },

  async submitOrder({ commit, getters }, orderData) {
    const params = {
      email: orderData.email,
      additionalPayment: getters["additionalPayment"],
      shipping_address: orderData.shippingAddress,
      billing_address: orderData.billingAddress,
      payment_points: {
        amount: getters["pointsDiscount"],
      },
      payment_financial: null,
    };
    const post = Object.assign(params, { cart: getters["cart"] });
    return await cartApi.submitOrder(post);

  },

  async setStepper({ commit }, step) {
    commit("setStepper", step);
    return step;
  },
  async trackCart({ commit }, data) {
    commit("trackCart", data);
  },
  async setCartArrayIndex({ commit }, cartArrayIndex) {
    commit("setCartArrayIndex", cartArrayIndex);
  }
};
